// Packages
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from 'react-intl';
// Components
import AddressDetails from "../../mobileComponents/addressDetails";
import PaymentDetailsMobile from "../../mobileComponents/paymentDetailsMobile";
import OrderPlacedPopup from "../../mobileComponents/orderPlacedPopup";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
// Redux Operations
import { errorOperations } from "../../state/features/error";
import { orderInProgressOperations } from "../../state/features/orderInProgress";
import { cartOperations } from "../../state/features/cart";
import { loaderOperations } from "../../state/features/loader";
import { paymentTypeOperations } from "../../state/features/paymentTypes";
// Helpers, Utils etc.
import {
  orderPayload,
} from "../../helpers/orderPayloadBuilder";
import {
  EMAIL_REGEX,
  NAME_REGEX,
  PAYMENT_PAID,
  MASTERCARD_PAYMENT_TYPE,
} from "../../helpers/constants";
import { calculateAmountsAndBreakdowns } from "../../helpers/itemCalculations";
import { outOfStockItemsList } from "../../helpers/cartFunctions";
import OutOfStockWarning from "../../mobileComponents/outOfStockWarningMobile";
import PaymentInProgressBanner from "../../mobileComponents/paymentInProgressBanner";
import CheckoutScreenProvider from "../../providers/mobile/checkoutScreenProvider";
import { CheckoutScreenContext } from "../../contexts/checkoutScreenContext";
import CheckoutCtaButton from "./ctaButton";

interface ICheckoutScreenProps {
  company: any;
  outlet: any;
  menu: any;
  session: any;
  cart: any;
  orderType: any;
  discount: any;
  loyalty: any;
  loyaltyConfig: any;
  user: any;
  storeOpen: boolean;
  selectedArea: any;
  redeemedPoints: any;
  paymentTypes: any;
  riderTip: number;
  updateError: any;
  placeOrder: any;
  setCart: any;
  showLoader: any;
  buildCCAvenuePaymentData: any;
  buildQpayPaymentData: any;
  buildIPay88PaymentData: any;
  buildFiservPaymentData: any;
  createGeideaTempOrder: any;
  recentOrder: any;
  appliedPromotions: any[];
  promotionAppliedCart: any[];
  appliedOrderPromotion: any[];
}
interface ICheckoutScreenState {
  selectedAddress: any;
  selectedPaymentType: any;
  selectedPaymentTypeTitle: any;
  orderPayload: any;
  stripeSelectedCard: any;
  showPlaceOrderPopup: boolean;
  pickupMode: string;
  orderNotes: string;
  metaNotes: string;
  curbsideNotes: any;
  scheduledTime: any;
  deliveryEta: any;
  displayEta: string;
  area: any;
  earnedLoyalty: any;
  userName: string;
  userEmail: string;
  myFatoorahSelectedPaymentMode: any;
  placeOrderEnabled: boolean;
  cardToken: any;
  minimumEtaMinutes: any;
  paymentTypes: any;
  outOfStockItems: any;
  genericPaymentTypeSelected: boolean;
  paymentDetails: any;
}

class CheckoutScreen extends Component<ICheckoutScreenProps, ICheckoutScreenState> {
  static contextType?: React.Context<any> | undefined = CheckoutScreenContext;
  constructor(props: any) {
    super(props);
    this.state = {
      selectedAddress: {},
      selectedPaymentType: "cash_on_delivery",
      selectedPaymentTypeTitle: "Cash on Delivery",
      orderPayload: {},
      stripeSelectedCard: {},
      showPlaceOrderPopup: false,
      pickupMode: "",
      orderNotes: "",
      metaNotes: "",
      curbsideNotes: {},
      scheduledTime: null,
      deliveryEta: new Date(),
      displayEta: "",
      earnedLoyalty: null,
      userName: "",
      userEmail: "",
      myFatoorahSelectedPaymentMode: "",
      placeOrderEnabled: true,
      cardToken: null,
      minimumEtaMinutes: 0,
      paymentTypes: [],
      outOfStockItems: [],
      genericPaymentTypeSelected: false,
      paymentDetails: null,
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.paymentTypes !== this.props.paymentTypes ||
      prevProps.cart.total !== this.props.cart.total
    ) {
      if (this.props.cart.total == 0) {
        let allowedPaymentTypes = this.props.paymentTypes.filter(
          (paymentType: any) => {
            return (
              paymentType.payment_type == "cash_on_delivery" ||
              paymentType.payment_type == "card_on_delivery"
            );
          }
        );
        if (allowedPaymentTypes.length) {
          this.setState({
            paymentTypes: allowedPaymentTypes,
          });
        } else {
          this.setState({ paymentTypes: this.props.paymentTypes });
        }
      } else {
        this.setState({ paymentTypes: this.props.paymentTypes });
      }
    }

    if (this.props.riderTip !== prevProps.riderTip) {
      this.updateItemsInCart(this.props.promotionAppliedCart);
    }

    if (
      this.props.promotionAppliedCart.length &&
      JSON.stringify(this.props.promotionAppliedCart) !==
        JSON.stringify(prevProps.promotionAppliedCart)
    ) {
      this.updateItemsInCart(this.props.promotionAppliedCart);
    }

    if (
      JSON.stringify(prevProps.cart.items) !=
        JSON.stringify(this.props.cart.items) ||
      JSON.stringify(prevProps.menu.out_of_stock_items) !=
        JSON.stringify(this.props.menu.out_of_stock_items)
    ) {
      this.setState({
        outOfStockItems: outOfStockItemsList(
          this.props.cart.items,
          this.props.menu.out_of_stock_items
        ),
      });
    }
  }

  userDetailsForGenericPayments() {
    return {
      id: this.props.user.sapaad_customer_id,
      name: this.props.user.name,
      email: this.props.user.email,
      phone: this.props.user.phone_number
    }
  }

  paymentDetailsForGenericPayments() {
    const paymentType = this.props.paymentTypes.find((i: any) => i.payment_type == this.state.selectedPaymentType)
    return {
      id: paymentType.sapaad_payment_type_id,
      name: paymentType.partner_name,
      type: paymentType.payment_type
    }
  }

  updateItemsInCart(cartItems: any) {
    let cart = calculateAmountsAndBreakdowns(
      cartItems,
      this.props.menu,
      this.props.orderType,
      this.props.discount,
      this.props.redeemedPoints,
      this.props.selectedArea,
      this.props.outlet.delivery_charge_id,
      this.props.riderTip,
      this.props.appliedOrderPromotion
    ) as any;
    cart.items = cartItems;

    this.props.setCart(cart);
    if (!cartItems?.length) {
      this.props.updateError({
        title: this.props.intl.formatMessage({ id: "error.oops", defaultMessage: "Oops!" }),
        message: this.props.intl.formatMessage({ id: "error.cart_is_empty", defaultMessage: "Your cart is empty! Please add items from the menu." }),
        redirectionUrl: "/m",
      });
    }
  }

  onStepBack() {
    window.location.href = "/m/order-details";
  }

  onStepNext() {
    if (!this.props.user.name && !NAME_REGEX.test(this.state.userName)) {
      return this.reportUserNameError();
    }
    if (this.state.pickupMode == "curbside") {
      let curbsideNotes = this.state.curbsideNotes;
      if (!curbsideNotes || !curbsideNotes['car_model'] || !curbsideNotes['car_color'] || !curbsideNotes['car_plate_number']) {
        return this.reportCurbsideNotesError();
      }
    }
    if (this.props.orderType == "delivery" && !this.state.selectedAddress.id) {
      this.props.updateError({
        title: this.props.intl.formatMessage({ id: "error.oops", defaultMessage: "Oops!" }),
        message: this.props.intl.formatMessage({ id: "error.select_an_address", defaultMessage: "Please select an address to continue" }),
      });
    } else if (this.paymentRequirementsFulfilled()) {
      this.handlePaymentDetailsConfirm();
    } else {
      this.reportPaymentRequirementsError();
    }
  }

  reportUserNameError() {
    let nameInput = document.getElementById("user_name");
    let containerPosition = nameInput?.closest(".container")?.offsetTop || 0;
    let headerHeight = document.getElementById("header")?.clientHeight || 0;
    window.scrollTo({ top: containerPosition - headerHeight });
    this.updateInputValidationMessage("user_name", this.props.intl.formatMessage({ id: "validation.user_name", defaultMessage: "Please enter a valid name." }));
    nameInput.reportValidity();
  }

  reportPaymentRequirementsError() {
    if (!EMAIL_REGEX.test(this.state.userEmail)) {
      let emailInput = document.getElementById("user_email");
      let containerPosition = emailInput?.closest(".payment-methods")?.offsetTop || 0;
      let headerHeight = document.getElementById("header")?.clientHeight || 0;
      window.scrollTo({ top: containerPosition - headerHeight });
      this.updateInputValidationMessage("user_email", this.props.intl.formatMessage({ id: "validation.email_address", defaultMessage: "Please enter a valid email address." }));
      emailInput.reportValidity();
    }
  }

  reportCurbsideNotesError() {
    let curbsideNotes = this.state.curbsideNotes;
    if (!curbsideNotes || !curbsideNotes['car_model']) {
      let carModelInput = document.getElementById("car_model");
      let containerPosition = carModelInput?.closest(".container")?.offsetTop || 0;
      let headerHeight = document.getElementById("header")?.clientHeight || 0;
      window.scrollTo({ top: containerPosition - headerHeight });
      this.updateInputValidationMessage("car_model", this.props.intl.formatMessage({ id: "validation.car_model", defaultMessage: "Please enter a valid car model." }));
      carModelInput.reportValidity();
    } else if (!curbsideNotes['car_color']) {
      let carColorInput = document.getElementById("car_color");
      let containerPosition = carColorInput?.closest(".container")?.offsetTop || 0;
      let headerHeight = document.getElementById("header")?.clientHeight || 0;
      window.scrollTo({ top: containerPosition - headerHeight });
      this.updateInputValidationMessage("car_color", this.props.intl.formatMessage({ id: "validation.car_color", defaultMessage: "Please enter a valid car color." }));
      carColorInput.reportValidity();
    } else if (!curbsideNotes['car_plate_number']) {
      let carPlateNumberInput = document.getElementById("car_plate_number");
      let containerPosition = carPlateNumberInput?.closest(".container")?.offsetTop || 0;
      let headerHeight = document.getElementById("header")?.clientHeight || 0;
      window.scrollTo({ top: containerPosition - headerHeight });
      this.updateInputValidationMessage("car_plate_number", this.props.intl.formatMessage({ id: "validation.car_plate_number", defaultMessage: "Please enter a valid car plate number." }));
      carPlateNumberInput.reportValidity();
    }
  }

  updateInputValidationMessage(inputFieldID: string, message: string) {
    document.getElementById(inputFieldID)?.setCustomValidity(message);
  }

  paymentRequirementsFulfilled() {
    if (["qpay", "pay_u", "i_pay88"].includes(this.state.selectedPaymentType)) {
      return this.props.user.email || EMAIL_REGEX.test(this.state.userEmail);
    }
    return true;
  }

  onSelectAddress(selectedAddress: any) {
    this.setState({ selectedAddress: selectedAddress });
  }

  setGenericPaymentTypeSelected(status: boolean) {
    this.setState({ genericPaymentTypeSelected: status })
  }

  handlePaymentDetailsConfirm() {
    this.togglePlaceOrderButton(false);

    let payload = orderPayload(this.state, this.props);
    let placeOrderPath;
    if (this.state.genericPaymentTypeSelected) {
      var genericCheckoutButton = document.getElementById("genericPaymentsCheckoutButton");
      genericCheckoutButton?.click();
      return;
    }
    switch (this.state.selectedPaymentType) {
      case "cash_on_delivery":
        placeOrderPath = "orders/cash_on_delivery";
        break;
      case "card_on_delivery":
        placeOrderPath = "orders/card_on_delivery";
        break;
      case "stripe":
        payload.payment.payment_status = PAYMENT_PAID;
        payload.payment.save_card = false;
        payload.payment.is_saved_card = false;
        payload.payment.transaction_id = null;
        payload.payment.ref_card_number = null;
        payload.payment.total_amount = 0;

        let new_card_form = document.getElementById("btn_card_section");
        if (new_card_form != null) {
          // payment with new card
          this.setState(
            { orderPayload: payload },
            () => new_card_form?.click()
          );
          return;
        } else {
          // payment with already saved card
          payload.payment.save_card = false;
          payload.payment.is_saved_card = true;
          payload.payment.transaction_id = this.state.stripeSelectedCard.id;
          payload.payment.ref_card_number = this.state.stripeSelectedCard.id;
          payload.payment.total_amount = payload.total_amount;
          placeOrderPath = "payments/stripe/v1/orders";
        }
        break;
      case "paytabs":
        placeOrderPath = "payments/paytabs/v2.1/payment-page";
        break;
      case "pay_u":
        placeOrderPath = "payments/pay_u/v1/payment_pages";
        payload.user_name = this.state.userName || this.props.user.name;
        payload.user_email = this.state.userEmail || this.props.user.email;
        break;
      case "my_fatoorah":
        placeOrderPath = "payments/my_fatoorah/v1/payment_pages";
        payload.myFatoorahSelectedPaymentMode = this.state.myFatoorahSelectedPaymentMode
        break;
      case "cc_avenue":
        this.props.buildCCAvenuePaymentData(payload).then((response: any) => {
          if (response.error) {
            this.handleOutofStockError(response);
          } else {
            this.setState({ orderPayload: response.payload.data });
          }
        });
        return;
      case "dpo":
        placeOrderPath = "payments/dpo/v1/payment_pages";
        break;
      case "qpay":
        payload.cardToken = this.state.cardToken;
        this.props.buildQpayPaymentData(payload).then((response: any) => {
          if (response.error) {
            this.handleOutofStockError(response);
          } else {
            this.setState({ orderPayload: response.payload.data });
          }
        });
        return;
      case "i_pay88":
        this.props.buildIPay88PaymentData(payload).then((response: any) => {
          if (response.error) {
            this.handleOutofStockError(response);
          } else {
            this.setState({ orderPayload: response.payload.data });
          }
        });
        return;
      case "fiserv":
        this.props.buildFiservPaymentData(payload).then((response: any) => {
          if (response.error) {
            this.handleOutofStockError(response);
          } else {
            this.setState({ orderPayload: response.payload.data });
          }
        });
        return;
      case "omise":
        let btnOmise = document.getElementById("omiseCheckoutButton");
        if (btnOmise != null) {
          this.setState(
            { orderPayload: payload },
            () => btnOmise?.click()
          );
        }
        return;
      case "adyen":
        var adyenBtn = document.getElementsByClassName('adyen-checkout__button--pay')[0];
        if (adyenBtn != null) {
          this.setState(
            { orderPayload: payload },
            () => adyenBtn?.click()
          );
        }
        return;
      case "geidea":
        payload.payment.card_token = this.state.cardToken;
        if (this.state.cardToken) {
          placeOrderPath = "payments/geidea/v1/orders"
          break;
        } else {
          this.props.createGeideaTempOrder(payload).then((response: any) => {
            if (!response.error) {
              var btnGeidea = document.getElementById("newCardCheckoutButton");
              this.setState(
                { orderPayload: response.payload.data },
                () => btnGeidea?.click()
              );
            }
          });
        }
        return;
      case "stripe_connect":
        var stripeConnectBtn = document.getElementById("stripeConnectPayButton");
        if (stripeConnectBtn != null) {
          this.setState(
            { orderPayload: payload },
            () => stripeConnectBtn?.click()
          );
        }
        return;
      case MASTERCARD_PAYMENT_TYPE:
        let mastercardCheckoutBtn =
          document.getElementById("mastercardDirectPaymentCheckoutButton") ||
          document.getElementById("mpgsApplePayButton") ||
          document.getElementById("mastercardCheckoutButton");
        if (mastercardCheckoutBtn != null) {
          this.setState(
            { orderPayload: payload },
            () => mastercardCheckoutBtn?.click()
          );
        }
        return; 
    }

    this.props.placeOrder(placeOrderPath, payload).then((response: any) => {
      if (!response.error) {
        if (response.payload.data.payment_page_url) {
          window.location.href = response.payload.data.payment_page_url;
        } else {
          let earnedLoyalty = null;
          localStorage.cartItems = JSON.stringify([]);
          this.props.setCart({ items: [], subTotal: 0, total: 0 });
          if (response.payload.data.order_details.earned_loyalty) {
            earnedLoyalty = response.payload.data.order_details.earned_loyalty;
          }
          this.showPointsEarnedPopup(earnedLoyalty);
        }
      }
      this.togglePlaceOrderButton(true);
    });
  }

  handleOutofStockError(response: any) {
    if (response.error) {
      let error_id = response?.error?.response?.data?.id;
      if (error_id && error_id === "out_of_stock") {
        this.props.updateError({
          subTitleCode: "error.out_of_stock",
          messageCode: "error.out_of_stock",
          redirectionUrl: "/",
        });
      }
    }
  }

  showPointsEarnedPopup(earnedLoyalty: any) {
    this.setState({
      showPlaceOrderPopup: true,
      earnedLoyalty: earnedLoyalty,
    });
  }

  handleStripeCardSelection(stripeCard: any) {
    this.setState({ stripeSelectedCard: stripeCard });
  }

  onPaymentSelect(paymentType: any, title: any) {
    this.setState({
      selectedPaymentType: paymentType,
      selectedPaymentTypeTitle: title
    }, () => {
      if (["adyen", "stripe_connect", "generic_fintech_partner", "mastercard"].includes(paymentType)) {
        this.setState({ orderPayload: orderPayload(this.state, this.props) });
      } else {
        this.setState({orderPayload: {}});
      }
    });
  }
  handlePlaceOrderPopupClose() {
    this.setState({ showPlaceOrderPopup: false });
  }

  changePickupMode(pickupMode: string) {
    this.setState({ pickupMode });
  }

  updateOrderNotes(notes: string) {
    this.setState({ orderNotes: notes });
  }

  updateMetaNotes(notes: string) {
    this.setState({ metaNotes: notes });
  }

  updateCurbsideNotes(notes: any) {
    this.setState({ curbsideNotes: notes });
  }

  updateScheduleTime(time: any) {
    this.setState({ scheduledTime: time });
  }

  updateEtas(deliveryEta: any, displayEta: string) {
    this.setState({ deliveryEta, displayEta });
  }

  updateUserName(userName: string) {
    this.updateInputValidationMessage("user_name", "");
    this.setState({ userName });
  }

  updateUserEmail(userEmail: string) {
    this.updateInputValidationMessage("user_email", "");
    this.setState({ userEmail: userEmail.trim() });
  }

  updateMyFatoorahSelectedPaymentMode(paymentModeId: any) {
    this.setState({ myFatoorahSelectedPaymentMode: paymentModeId });
  }

  togglePlaceOrderButton(status: boolean) {
    this.setState({ placeOrderEnabled: status });
  }

  updateCardToken(cardToken: any) {
    this.setState({ cardToken });
  }

  updateMinimumOrderEta(minimumEtaMinutes: any) {
    this.setState({ minimumEtaMinutes });
  }

  render() {
    return (
      <React.Fragment>
        <CheckoutScreenProvider>
        <div className="checkout-screen-mobile">
          {this.state.outOfStockItems.length ? (
            <OutOfStockWarning outOfStockItems={this.state.outOfStockItems} />
          ) : null}
          <AddressDetails
            onAddressSelect={(selectedAddress: any) =>
              this.onSelectAddress(selectedAddress)
            }
            pickupMode={this.state.pickupMode}
            orderNotes={this.state.orderNotes}
            metaNotes={this.state.metaNotes}
            curbsideNotes={this.state.curbsideNotes}
            displayEta={this.state.displayEta}
            userName={this.state.userName}
            userEmail={this.state.userEmail}
            minimumEtaMinutes={this.state.minimumEtaMinutes}
            changePickupMode={(mode: string) => this.changePickupMode(mode)}
            updateOrderNotes={(notes: string) => this.updateOrderNotes(notes)}
            updateMetaNotes={(notes: string) => this.updateMetaNotes(notes)}
            updateCurbsideNotes={(notes: any) => this.updateCurbsideNotes(notes)}
            updateScheduleTime={(time: any) => this.updateScheduleTime(time)}
            updateEtas={(deliveryEta: any, displayEta: string) => {
              this.updateEtas(deliveryEta, displayEta)
            }}
            updateUserName={(userName: string) => {
              this.updateUserName(userName)
            }}
            updateUserEmail={(userEmail: string) => {
              this.updateUserEmail(userEmail)
            }}
            updateMinimumOrderEta={(minimumEtaMinutes: string) => {
              this.updateMinimumOrderEta(minimumEtaMinutes)
            }}
          />
          <PaymentDetailsMobile
            payload={this.state.orderPayload}
            onPaymentSelect={(paymentType: any, title: any) =>
              this.onPaymentSelect(paymentType, title)
            }
            showPointsEarnedPopup={(earnedLoyalty: any) =>
              this.showPointsEarnedPopup(earnedLoyalty)
            }
            handleStripeCardSelection={(stripeCard: any) =>
              this.handleStripeCardSelection(stripeCard)
            }
            userEmail={this.state.userEmail}
            updateUserEmail={(userEmail: string) => {
              this.updateUserEmail(userEmail)
            }}
            userName={this.state.userName}
            myFatoorahSelectedPaymentMode={this.state.myFatoorahSelectedPaymentMode}
            updateMyFatoorahSelectedPaymentMode={(paymentModeId: any) =>
              this.updateMyFatoorahSelectedPaymentMode(paymentModeId)
            }
            togglePlaceOrderButton={(status: boolean) =>
              this.togglePlaceOrderButton(status)
            }
            cardToken={this.state.cardToken}
            updateCardToken={(token: any) => this.updateCardToken(token)}
            companyDetails={this.props.company}
            paymentTypes={this.state.paymentTypes}
            setGenericPaymentTypeSelected={(status: boolean) =>
              this.setGenericPaymentTypeSelected(status)
            }
            userDetailsForGenericPayments={() =>
              this.userDetailsForGenericPayments()
            }
            paymentDetailsForGenericPayments={() =>
              this.paymentDetailsForGenericPayments()
            }
            setPaymentDetails={(details: any) =>
              this.setState({ paymentDetails: details })
            }
          />
          {this.state.showPlaceOrderPopup && (
            <OrderPlacedPopup earnedLoyalty={this.state.earnedLoyalty} />
          )}
          
          {this.props.recentOrder ? (
            <div className="in-progress-banner-wrapper">
              <PaymentInProgressBanner />
            </div>
          ) : null}
          <div className="footer-actions d-flex">
            <CheckoutCtaButton
              placeOrderEnabled={this.state.placeOrderEnabled}
              outOfStockItems={this.state.outOfStockItems}
              recentOrder={this.props.recentOrder}
              orderPayload={this.state.orderPayload}
              storeOpen={this.props.storeOpen}
              cartItems={this.props.promotionAppliedCart}
              clickAction={() => this.onStepNext()}
              backAction={() => this.props.showLoader()}
            />
          </div>
        </div>
        </CheckoutScreenProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  let company = state.company;
  let outlet = state.outlet;
  let menu = state.menu;
  let session = state.session;
  let orderType = state.session.order_type;
  let cart = state.cart;
  let discount = state.discount;
  let loyalty = state.loyalty;
  let loyaltyConfig = state.loyaltyConfig;
  let user = state.user;
  let storeOpen = state.storeTimings.open;
  let selectedArea = state.areas.selectedArea;
  let redeemedPoints = state.loyalty?.redeemedPoints;
  let paymentTypes = state.paymentTypes;
  let riderTip = state.riderTip;
  let recentOrder = state.recentOrder;
  let appliedPromotions = state.promotions.appliedPromotions;
  let promotionAppliedCart = state.promotions.promotionAppliedCart;
  let appliedOrderPromotion = state.promotions.appliedOrderPromotion;

  return {
    company,
    outlet,
    menu,
    session,
    cart,
    orderType,
    discount,
    loyalty,
    loyaltyConfig,
    user,
    storeOpen,
    selectedArea,
    redeemedPoints,
    paymentTypes,
    riderTip,
    recentOrder,
    appliedPromotions,
    promotionAppliedCart,
    appliedOrderPromotion
  };
};

const mapDispatchToState = {
  updateError: errorOperations.updateError,
  placeOrder: orderInProgressOperations.placeOrder,
  setCart: cartOperations.setCart,
  showLoader: loaderOperations.showLoader,
  buildCCAvenuePaymentData: paymentTypeOperations.buildCCAvenuePaymentData,
  buildQpayPaymentData: paymentTypeOperations.buildQpayPaymentData,
  buildIPay88PaymentData: paymentTypeOperations.buildIPay88PaymentData,
  buildFiservPaymentData: paymentTypeOperations.buildFiservPaymentData,
  createGeideaTempOrder: paymentTypeOperations.createGeideaTempOrder,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToState)(CheckoutScreen));
