import actions from "./actions";

const verifyPayment = actions.verifyPayment;
const buildCCAvenuePaymentData = actions.buildCCAvenuePaymentData;
const fetchMyFatoorahPaymentModes = actions.fetchMyFatoorahPaymentModes;
const createGeideaTempOrder = actions.createGeideaTempOrder;
const buildQpayPaymentData = actions.buildQpayPaymentData;
const buildMastercardSession = actions.buildMastercardSession;
const submitMastercardDirectPaymentFormData = actions.submitMastercardDirectPaymentFormData;
const validateMastercardApplePayMerchant = actions.validateMastercardApplePayMerchant;
const makeMastercardApplePayPayment = actions.makeMastercardApplePayPayment;
const userCancelledApplePayPayment = actions.userCancelledApplePayPayment;
const submitMastercardGooglePayTokenData = actions.submitMastercardGooglePayTokenData;
const buildIPay88PaymentData = actions.buildIPay88PaymentData;
const buildFiservPaymentData = actions.buildFiservPaymentData;
const omisePlaceOrder = actions.omisePlaceOrder;
const buildAdyenPaymentMethods = actions.buildAdyenPaymentMethods;
const createAdyenPayment = actions.createAdyenPayment
const initiateStripeConnectTransaction = actions.initiateStripeConnectTransaction;
const fetchStripeConnectPaymentIntent = actions.fetchStripeConnectPaymentIntent;
const buildGenericPaymentsPayload = actions.buildGenericPaymentsPayload;

export default {
  verifyPayment,
  buildCCAvenuePaymentData,
  fetchMyFatoorahPaymentModes,
  createGeideaTempOrder,
  buildQpayPaymentData,
  buildIPay88PaymentData,
  buildFiservPaymentData,
  omisePlaceOrder,
  buildAdyenPaymentMethods,
  createAdyenPayment,
  initiateStripeConnectTransaction,
  fetchStripeConnectPaymentIntent,
  buildMastercardSession,
  buildGenericPaymentsPayload,
  submitMastercardDirectPaymentFormData,
  validateMastercardApplePayMerchant,
  makeMastercardApplePayPayment,
  userCancelledApplePayPayment,
  submitMastercardGooglePayTokenData,
};
