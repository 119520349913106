import { applyDeliveryPromotion, calculateOrderPromotionDiscount } from "../features/promotions/helpers/utils";
import { store } from "../state/store";
import {
  FIXED_SURCHARGE,
  ORDER_TYPE_DELIVERY,
} from "./constants";

const calculateSubTotal = (item: any) => {
  let price = item.unit_price * (item.count || 0);
  let subTotal = 0;
  let modifierSubtotal = 0;
  if (item.groups.length) {
    item.groups.map((group: any) => {
      modifierSubtotal += group.items.reduce((amount: number, item: any) => {
        if (item.selected) {
          if (item.count) {
            if (item.count > 0) {
              return amount + item.final_price * item.count;
            }
          } else {
            return amount + item.final_price;
          }
        }
        return amount;
      }, 0);
    });
    subTotal = price + modifierSubtotal * (item.count || 0);
  } else {
    subTotal = price;
  }

  return subTotal;
};

const findItemTotalAndTax = (
  itemPrice: number,
  itemTaxes: any,
  taxes: any,
  taxInclusive: boolean
) => {
  let itemTotal = itemPrice;
  let taxTotal = 0;
  if (itemTaxes.length) {
    itemTaxes.map((itemTax: any) => {
      itemTax.taxes.map((tax: any) => {
        let taxRate = findTaxRate(tax, taxes);
        if (taxInclusive) {
          taxTotal += itemPrice - (itemPrice / (1 + taxRate));
        } else {
          itemTotal += itemPrice * taxRate;
          taxTotal += itemPrice * taxRate;
        }
      });
    });
  }
  return {
    itemTotal,
    taxTotal
  };
};

const findTaxRate = (taxId: any, taxes: any) => {
  let taxRate = 0;
  if (taxes.length) {
    let tax = taxes.find((tax: any) => tax.id === taxId);
    if (tax && tax.type === "PERCENTAGE") {
      taxRate = tax.value / 100;
    }
  }
  return taxRate;
};

const calculateSubTotalAndTotal = (items: any, menu: any) => {
  let total = 0;
  let subTotal = 0;
  let tax = 0;
  items.forEach((item: any) => {
    subTotal += parseFloat(item.sub_total);
    let totalAndTaxObj: any = findItemTotalAndTax(
      parseFloat(item.sub_total), item.taxes, menu.taxes, menu.is_tax_inclusive
    )
    total += parseFloat(totalAndTaxObj.itemTotal);
    tax += parseFloat(totalAndTaxObj.taxTotal);
  });

  return {
    subTotal,
    total,
    tax,
  };
}

const calculateSurcharges = (
  surcharges: any,
  cartSubTotal: any,
  taxInclusive: boolean,
  orderType: string,
  taxes: any,
  deliveryCharge: any,
  deliveryChargeId: any,
  orderPromotions: any[] = []
) => {
  let subTotal = 0;
  let total = 0;
  let tax = 0;
  surcharges = surcharges.filter((charge: any) => {
    if (orderType == ORDER_TYPE_DELIVERY) {
      return charge.applicable_on_weborder;
    } else {
      return charge.applicable_on_takeaway && charge.id != deliveryChargeId;
    }
  });
  let breakDown = surcharges.map((charge: any) => {
    let chargeAmount: number, chargeValue: number;
    if (charge.id == deliveryChargeId) {
      chargeValue = deliveryCharge == null ? charge.value : deliveryCharge;
    } else {
      chargeValue = charge.value;
    }
    if (charge.type === FIXED_SURCHARGE) {
      chargeAmount = chargeValue;
    } else {
      chargeAmount = cartSubTotal * (chargeValue / 100);
    }

    let promotionAppliedChargeAmount = chargeAmount
    if (orderPromotions.length && orderPromotions[0].action.surcharge) {
      promotionAppliedChargeAmount = applyDeliveryPromotion(
        orderPromotions[0],
        charge.id,
        chargeAmount
      );
    }
    chargeAmount = promotionAppliedChargeAmount;
    subTotal += promotionAppliedChargeAmount;

    let totalAndTaxObj: any = findItemTotalAndTax(
      chargeAmount, charge.taxes, taxes, taxInclusive
    )
    total += parseFloat(totalAndTaxObj.itemTotal);
    tax += parseFloat(totalAndTaxObj.taxTotal);

    return {
      name: charge.name, amount: chargeAmount, type: charge.type
    }
  });

  return {
    subTotal,
    tax,
    total,
    breakDown,
  };
}

const calculateAmountsAndBreakdowns = (
  items: any,
  menu: any,
  orderType: string,
  discount: any = null,
  loyalty: any = null,
  area: any = {},
  deliveryChargeId: any = null,
  tips: number | null = null,
  orderPromotions: any[] = []
) => {
  // Finding Subtotal, Total and Tax of Items in Cart
  let totalObject = calculateSubTotalAndTotal(items, menu);
  let itemSubTotal = totalObject.subTotal;
  let itemTaxTotal = totalObject.tax;

  // Finding Subtotal, Total and Tax of Surcharges
  let surchargeObject = calculateSurcharges(
    menu.surcharges, itemSubTotal, menu.is_tax_inclusive, orderType,
    menu.taxes, area.delivery_charge, deliveryChargeId, orderPromotions
  );
  let surchargeSubTotal = surchargeObject.subTotal;
  let surchargeTaxTotal = surchargeObject.tax;
  let taxTotal = itemTaxTotal + surchargeTaxTotal;

  let totalAmount;
  let discountTotal = 0;
  let discountedAmount = 0;
  let discountedTax = 0;
  let subTotalAfterDiscount = 0;
  let subTotalTaxAfterDiscount = 0;
  let orderPromotionDiscount = 0;

  // Finding discounted item price and tax
  if ( discount ) {
    discountedAmount = discount.discountedAmount;
    let discountPercentage = parseFloat(discountedAmount) /
      parseFloat(discount.maxdiscountableAmount);
    discountPercentage = discountPercentage || 0;
    if ( discount.on_total ) {
      discountedTax = taxTotal * discountPercentage;
    } else {
      discountedTax = itemTaxTotal * discountPercentage;
    }
    if (menu.is_tax_inclusive) {
      discountTotal = discountedAmount;
    } else {
      discountTotal = discountedAmount + discountedTax;
    }

    subTotalAfterDiscount = itemSubTotal - itemSubTotal * discountPercentage;
    subTotalTaxAfterDiscount = itemTaxTotal - itemTaxTotal * discountPercentage;
  }
  taxTotal = taxTotal - discountedTax;
  if (menu.is_tax_inclusive) {
    totalAmount = parseFloat(formatDecimal(itemSubTotal)) +
      parseFloat(formatDecimal(surchargeSubTotal)) -
      parseFloat(formatDecimal(discountedAmount));
  } else {
    totalAmount = parseFloat(formatDecimal(itemSubTotal)) +
      parseFloat(formatDecimal(surchargeSubTotal)) +
      parseFloat(formatDecimal(taxTotal)) -
      parseFloat(formatDecimal(discountedAmount));
  }
  let totalBeforeDiscount = totalAmount +
    parseFloat(formatDecimal(discountedAmount)) +
    parseFloat(formatDecimal(discountedTax));

  let totalBeforeLoyalty = totalAmount;
  let loyaltyTax = 0;
  let loyaltyAsDiscount = store.getState().loyaltyConfig?.is_discount;
  if (loyaltyAsDiscount) {
    totalBeforeLoyalty =
      parseFloat(formatDecimal(itemSubTotal)) +
      parseFloat(formatDecimal(surchargeSubTotal)) -
      parseFloat(formatDecimal(discountedAmount));
  }
  if ( loyalty ) {
    if (loyaltyAsDiscount) {
      let loyaltyPercentage = parseFloat(loyalty) / parseFloat(totalBeforeLoyalty);
      loyaltyTax = taxTotal * loyaltyPercentage;
    }

    taxTotal -= loyaltyTax;
    if (menu.is_tax_inclusive) {
      totalAmount =
        parseFloat(formatDecimal(itemSubTotal)) +
        parseFloat(formatDecimal(surchargeSubTotal)) -
        parseFloat(formatDecimal(discountedAmount)) -
        parseFloat(formatDecimal(loyalty));
    } else {
      totalAmount =
        parseFloat(formatDecimal(itemSubTotal)) +
        parseFloat(formatDecimal(surchargeSubTotal)) +
        parseFloat(formatDecimal(taxTotal)) -
        parseFloat(formatDecimal(discountedAmount)) -
        parseFloat(formatDecimal(loyalty));
    }
  }
  if (orderPromotions.length) {
    const highestPriotityPromotion = orderPromotions[0];
    orderPromotionDiscount = calculateOrderPromotionDiscount(
      highestPriotityPromotion,
      itemSubTotal,
      surchargeSubTotal
    );
  }
  totalAmount -= orderPromotionDiscount
  let totalBeforeTip = totalAmount;
  if (Number(tips) > 0) {
    totalAmount += parseFloat(formatDecimal(tips));
  }

  return {
    subTotal: formatDecimal(itemSubTotal),
    total: formatDecimal(totalAmount),
    surchargeSubTotal: formatDecimal(surchargeSubTotal),
    surchargeTotal: formatDecimal(surchargeObject.total),
    discountTotal: formatDecimal(discountTotal),
    loyaltyTotal: formatDecimal(loyalty),
    taxTotal: formatDecimal(taxTotal),
    surchargeBreakDown: surchargeObject.breakDown,
    totalBeforeDiscount: formatDecimal(totalBeforeDiscount),
    totalBeforeLoyalty: formatDecimal(totalBeforeLoyalty),
    subTotalAfterDiscount: formatDecimal(subTotalAfterDiscount),
    subTotalTaxAfterDiscount: formatDecimal(subTotalTaxAfterDiscount),
    tips: formatDecimal(tips),
    orderPromotionDiscount: formatDecimal(orderPromotionDiscount),
    totalBeforeTip: formatDecimal(totalBeforeTip),
  };
};

const formatDecimal = (number: any, precision = localStorage.precision) => {
  if (!precision) {
    precision = 2;
  }

  number = parseFloat(parseFloat(number).toFixed(7));

  let rounder = Math.pow(10, precision);

  return (Math.round(number * rounder) / rounder).toFixed(precision);
}

export {
  calculateSubTotal,
  calculateAmountsAndBreakdowns,
  formatDecimal
};
