import {
  PROMOTION_ACTIONS,
  PROMOTION_CONDITION_TYPES,
  PROMOTION_TYPES,
} from "../types/promotionTypes";
import { v4 as uuidv4 } from "uuid";

/**
 * Appends category details to a cart item based on its ID.
 * @param cartItem - The cart item to append category details to.
 * @param categories - An array of categories containing items.
 * @returns The updated cart item with category details.
 */
function appendCategoryDetails(cartItem: any, categories: any[]) {
  let newCartItem: any = null;
  categories.map((category) => {
    category.items.map((item: any) => {
      if (item.id === cartItem.id) {
        newCartItem = {
          ...cartItem,
          category_id: category.id,
          category_name: category.name,
        };
      } else if (item.items && item.items.length) {
        //grouped items
        item.items.map((subItem: any) => {
          if (subItem.id === cartItem.id) {
            newCartItem = {
              ...cartItem,
              category_id: category.id,
              category_name: category.name,
            };
          }
        });
      }
    });
  });
  return newCartItem;
}

/**
 * Duplicates cart items based on their count.
 * @param cartItems - An array of cart items.
 * @returns The updated array of cart items with duplicated items.
 */
function duplicateCartItems(cartItems: any[]) {
  const duplicatedCartItems: any[] = [];
  cartItems.forEach((cartItem) => {
    if (cartItem?.count > 1) {
      for (let i = 0; i < cartItem.count; i++) {
        const duplicatedItem = { ...cartItem };
        duplicatedItem.temp_uuid = uuidv4(); // Add UUID to the item
        duplicatedItem.count = 1;
        duplicatedItem.sub_total = cartItem.sub_total / cartItem.count;
        duplicatedCartItems.push(duplicatedItem);
      }
    } else {
      const item = { ...cartItem };
      item.temp_uuid = uuidv4(); // Add UUID to the item
      duplicatedCartItems.push(item);
    }
  });
  return duplicatedCartItems;
}

function remergeCart(cartItems: any[]) {
  const mergedCartItems: any[] = [];
  cartItems.forEach((cartItem) => {
    const existingItem = mergedCartItems.find(
      (item) => item.cart_id === cartItem.cart_id
    );
    if (existingItem) {
      existingItem.count += 1;
      existingItem.sub_total += cartItem.sub_total;
      delete existingItem.temp_uuid;
    } else {
      delete cartItem.temp_uuid;
      mergedCartItems.push(cartItem);
    }
  });
  return mergedCartItems;
}

/**
 * Finds cart subtotal
 * @param cartItems - An array of cart items.
 * @returns The updated subtotal of the cart items
 */
function findCartSubtotal(cartItems: any[]) {
  return cartItems.reduce((subtotal, item) => {
    return subtotal + item.sub_total;
  }, 0);
}

/**
 * Sorts promotions by priority
 * @param availablePromotions - An array of available promotions.
 * @returns sorted promotions by priority
 */
function sortPromotions(availablePromotions: any[]) {
  const sortedPromotions = availablePromotions.sort(
    (a, b) => a.priority - b.priority
  );
  return sortedPromotions;
}

function findCartAppliedPromotions(cart: any[], promotions: any[]) {
  const appliedPromotions: any[] = [];
  cart.forEach((cartItem) => {
    const promotion = promotions.find(
      (promotion: any) => promotion.id === cartItem.applied_promotion_id
    );
    if (promotion && !appliedPromotions.includes(promotion)) {
      appliedPromotions.push(promotion);
    }
  });
  return appliedPromotions;
}

//--------------------BASIC PROMO CALCULATIONS
function applyPercentageDiscount(cartItems: any[], promotion: any) {
  let itemTotal = 0;
  let maxDiscount = promotion.action.max_discount_value;
  let discountValue = promotion.action.value;
  let totalDiscount = 0;
  return cartItems.map((item) => {
    if (
      item.applied_promotion_id &&
      item.applied_promotion_priority <= promotion.priority
    ) {
      return item;
    }
    itemTotal = promotion.action.price_modifiers
      ? item.sub_total
      : item.unit_price;
    totalDiscount = item.sub_total - (itemTotal * discountValue) / 100;
    totalDiscount =
      maxDiscount > 0 && totalDiscount > maxDiscount
        ? maxDiscount
        : totalDiscount;
    return {
      ...item,
      sub_total: totalDiscount,
      applied_promotion_id: promotion.id,
      applied_promotion_priority: promotion.priority,
    };
  });
}

function applyValueDiscount(cartItems: any[], promotion: any) {
  let maxDiscount = promotion.action.max_discount_value;
  let discountValue = promotion.action.value;
  let totalDiscount = 0;
  return cartItems.map((item) => {
    if (
      item.applied_promotion_id &&
      item.applied_promotion_priority <= promotion.priority
    ) {
      return item;
    }
    totalDiscount = item.sub_total - discountValue;
    totalDiscount =
      maxDiscount > 0 && totalDiscount > maxDiscount
        ? maxDiscount
        : totalDiscount;
    return {
      ...item,
      sub_total: item.sub_total - discountValue,
      applied_promotion_id: promotion.id,
      applied_promotion_priority: promotion.priority,
    };
  });
}

function freeItem(cartItems: any[], promotion: any) {
  let itemTotal = 0;
  let totalDiscount = 0;
  return cartItems.map((item) => {
    if (
      item.applied_promotion_id &&
      item.applied_promotion_priority <= promotion.priority
    ) {
      return item;
    }
    itemTotal = promotion.action.price_modifiers
      ? item.sub_total
      : item.unit_price;
    totalDiscount = item.sub_total - itemTotal;
    return {
      ...item,
      sub_total: totalDiscount,
      applied_promotion_id: promotion.id,
      applied_promotion_priority: promotion.priority,
    };
  });
}
/**
 * Finds promotion applicable items in the cart based on the promotion's include and exclude criteria.
 * @param cartItems - An array of cart items.
 * @param promotionAppliableItems - An array of promotion applicable items.
 * @returns The array of applicable items for the promotion.
 */
function findPromotionApplicableItems(
  cartItems: any[],
  promotionAppliableItems: any[]
) {
  const applicableItems: any[] = [];
  promotionAppliableItems.forEach((item) => {
    if (item.field === "item_id") {
      const items = cartItems.filter((cartItem) => cartItem.id == item.id);
      applicableItems.push(...items);
    } else {
      const items = cartItems.filter(
        (cartItem) => cartItem.category_id == item.id
      );
      applicableItems.push(...items);
    }
  });
  return applicableItems;
}

function findPromotionUnapplicableItems(
  cartItems: any[],
  applicableItems: any[]
) {
  return cartItems.filter((cartItem) => {
    return !applicableItems.includes(cartItem);
  });
}

function combineDiscountedAndNonApplicableItems(
  discountedItems: any[],
  nonapplicableItems: any[]
): any[] {
  return [...discountedItems, ...nonapplicableItems];
}

function applyDiscount(
  cart: any[],
  promotion: any,
  discountFunction: (items: any[], promotion: any) => any[]
): any[] {
  if (
    promotion.action.include.length === 0 &&
    promotion.action.exclude.length === 0
  ) {
    return discountFunction(cart, promotion);
  }

  if (promotion.action.include.length) {
    const applicableItems = findPromotionApplicableItems(
      cart,
      promotion.action.include
    );
    const nonapplicableItems = findPromotionUnapplicableItems(
      cart,
      applicableItems
    );
    const discountedApplicableItems = discountFunction(
      applicableItems,
      promotion
    );
    return combineDiscountedAndNonApplicableItems(
      discountedApplicableItems,
      nonapplicableItems
    );
  } else {
    const nonapplicableItems = findPromotionApplicableItems(
      cart,
      promotion.action.exclude
    );
    const applicableItems = findPromotionUnapplicableItems(
      cart,
      nonapplicableItems
    );
    const discountedNonapplicableItems = discountFunction(
      applicableItems,
      promotion
    );
    return combineDiscountedAndNonApplicableItems(
      discountedNonapplicableItems,
      nonapplicableItems
    );
  }
}

//check if basic promotion conditions are satisfied
function applyBasicPromotion(
  promotionAppliedCart: any[],
  promotion: any,
  orderPromotion: any[]
) {
  const isDiscountOnOrder =
    promotion.action.type === PROMOTION_ACTIONS.PERCENTAGE_DISCOUNT_ON_ORDER ||
    promotion.action.type === PROMOTION_ACTIONS.VALUE_DISCOUNT_ON_ORDER;
  if (isDiscountOnOrder) {
    //discount on entire order
    orderPromotion.push(promotion);
  } else if (promotion.action.type === PROMOTION_ACTIONS.PERCENTAGE_DISCOUNT) {
    promotionAppliedCart = applyDiscount(
      promotionAppliedCart,
      promotion,
      applyPercentageDiscount
    );
  } else if (promotion.action.type === PROMOTION_ACTIONS.VALUE_DISCOUNT) {
    promotionAppliedCart = applyDiscount(
      promotionAppliedCart,
      promotion,
      applyValueDiscount
    );
  }
  return { promotionAppliedCart, orderPromotion };
}

//-------------------ADVANCED PROMO CALCULATIONS
function applyAdvancedDiscount(
  applicableItems: any[],
  promotion: any,
  discountFunction: (items: any[], promotion: any) => any[]
): any[] {
  const discountedItems = discountFunction(applicableItems, promotion);
  return discountedItems;
}

function filterOutPromotionAppliedItems(
  applicableItems: any[],
  newPromotion: any
) {
  return applicableItems.filter(
    (item) =>
      !item.applied_promotion_id ||
      item.applied_promotion_priority > newPromotion.priority
  );
}

function applyFixedPricePromotion(
  applicableItems: any[],
  promotionAction: any,
  promotionPriority: number
) {
  if (promotionAction.price_modifiers) {
    return applicableItems.map((item) => {
      return {
        ...item,
        sub_total: promotionAction.value,
        applied_promotion_id: promotionAction.id,
        applied_promotion_priority: promotionPriority,
      };
    });
  } else {
    return applicableItems.map((item) => {
      const modifierPrice =
        parseFloat(item.sub_total) - parseFloat(item.unit_price);
      return {
        ...item,
        sub_total: item.unit_price + modifierPrice,
        applied_promotion_id: promotionAction.id,
        applied_promotion_priority: promotionPriority,
      };
    });
  }
}

/**
 * Filters the promotion applicable and unapplicable items based on the promotion action's quantity.
 * @param promotionApplicableItems - An array of promotion applicable items.
 * @param promotionUnapplicableItems - An array of promotion unapplicable items.
 * @param promotionActionItemsQuantity - The quantity of items required for the promotion action.
 * @param cartItems - An array of cart items.
 * @returns The filtered promotion applicable and unapplicable items.
 */
const filterAdvancedPromoActionItems = (
  promotionApplicableItems: any[],
  promotionUnapplicableItems: any[],
  promotionActionItemsQuantity: number,
  cartItems: any[]
) => {
  if (
    promotionActionItemsQuantity > 0 &&
    promotionApplicableItems.length === promotionActionItemsQuantity
  ) {
    //exact quantity of items required for promotion action
    return { promotionApplicableItems, promotionUnapplicableItems };
  } else if (
    promotionActionItemsQuantity > 0 &&
    promotionApplicableItems.length > promotionActionItemsQuantity
  ) {
    //more than required quantity of items for promotion so spliting the items
    const { slicedItems, remainingItems } = splitItemsByQuantity(
      promotionApplicableItems,
      promotionActionItemsQuantity
    );
    promotionApplicableItems = slicedItems;
    promotionUnapplicableItems = [
      ...promotionUnapplicableItems,
      ...remainingItems,
    ];
    return { promotionApplicableItems, promotionUnapplicableItems };
  } else {
    //no items required for promotion action
    promotionApplicableItems = [];
    promotionUnapplicableItems = cartItems;
    return { promotionApplicableItems, promotionUnapplicableItems };
  }
};

/**
 * Finds the promotion action items based on the given promotion and cart items.
 * 
 * @param promotion - The promotion object.
 * @param cartItems - The array of cart items.
 * @returns An obj of action items and non applicableItems.
 */
function findPromotionActionItems(promotion: any, cartItems: any[]) {
  const { include, exclude } = promotion.action;
  let promotionAppliableItems: any[] = [];
  let promotionUnappliableItems: any[] = [];
  const promotionActionItemsQuantity = promotion.action.quantity;
  if (include.length === 0 && exclude.length === 0) {
    promotionAppliableItems = filterOutPromotionAppliedItems(
      cartItems,
      promotion
    );
    promotionUnappliableItems = cartItems.filter(
      (item) => !promotionAppliableItems.includes(item)
    );
    return filterAdvancedPromoActionItems(
      promotionAppliableItems,
      promotionUnappliableItems,
      promotionActionItemsQuantity,
      cartItems
    );
  } else if (include.length) {
    const promotionIncludeItems = findPromotionApplicableItems(
      cartItems,
      include
    );
    promotionAppliableItems = filterOutPromotionAppliedItems(
      promotionIncludeItems,
      promotion
    );
    promotionUnappliableItems = cartItems.filter(
      (item) => !promotionIncludeItems.includes(item)
    );
    return filterAdvancedPromoActionItems(
      promotionAppliableItems,
      promotionUnappliableItems,
      promotionActionItemsQuantity,
      cartItems
    );
  } else {
    const promotionExcludeItems = findPromotionApplicableItems(
      cartItems,
      exclude
    );
    const promotionIncludeItems = cartItems.filter(
      (item) => !promotionExcludeItems.includes(item)
    );
    promotionAppliableItems = filterOutPromotionAppliedItems(
      promotionIncludeItems,
      promotion
    );
    promotionUnappliableItems = cartItems.filter(
      (item) => !promotionIncludeItems.includes(item)
    );
    return filterAdvancedPromoActionItems(
      promotionAppliableItems,
      promotionUnappliableItems,
      promotionActionItemsQuantity,
      cartItems
    );
  }
}

function applyPromotionAction(
  promotionAppliedCart: any[],
  promotion: any,
  orderPromotions: any[]
): any[] {
  const promotionAction = promotion.action;
  let applicableItems: any[] = [];
  let unapplicableItems: any[] = [];
  if (!promotionAction.surcharge?.id) {
    const promotionActionItems = findPromotionActionItems(promotion, promotionAppliedCart);
    applicableItems = promotionActionItems.promotionApplicableItems;
    unapplicableItems = promotionActionItems.promotionUnapplicableItems;
  }
 
  switch (promotionAction.type) {
    case PROMOTION_ACTIONS.PAY_FIXED_PRICE:
      let fixedPromoAppliedItems: any[] = applyFixedPricePromotion(
        applicableItems,
        promotion.action,
        promotion.priority
      );
      return [...fixedPromoAppliedItems, ...unapplicableItems];
    case PROMOTION_ACTIONS.PERCENTAGE_DISCOUNT_ON_ORDER:
    case PROMOTION_ACTIONS.VALUE_DISCOUNT_ON_ORDER:
    case PROMOTION_ACTIONS.EARN_LOYALTY:
    case PROMOTION_ACTIONS.GETS_FREE_DELIVERY:
    case PROMOTION_ACTIONS.GETS_PERCENTAGE_DISCOUNT_ON_DELIVERY:
    case PROMOTION_ACTIONS.GETS_VALUE_DISCOUNT_ON_DELIVERY:
      orderPromotions.push(promotion);
      return promotionAppliedCart;
    case PROMOTION_ACTIONS.PERCENTAGE_DISCOUNT_ON_ITEM:
    case PROMOTION_ACTIONS.PERCENTAGE_DISCOUNT:
      const percentageDiscountAppliedItem = applyAdvancedDiscount(
        applicableItems,
        promotion,
        applyPercentageDiscount
      );
      return [...percentageDiscountAppliedItem, ...unapplicableItems];
    case PROMOTION_ACTIONS.VALUE_DISCOUNT_ON_ITEM:
    case PROMOTION_ACTIONS.VALUE_DISCOUNT:
      const valueDiscountAppliedItems = applyAdvancedDiscount(
        promotionAppliedCart,
        promotion,
        applyValueDiscount
      );
      return [...valueDiscountAppliedItems, ...unapplicableItems];
    case PROMOTION_ACTIONS.GETS_FREE_ITEM:
      const freeDiscountAppliedItems = applyAdvancedDiscount(
        promotionAppliedCart,
        promotion,
        freeItem
      );
      return [...freeDiscountAppliedItems, ...unapplicableItems];
    default:
      return promotionAppliedCart;
  }
}

function splitItemsByQuantity(applicableItems: any[], exactQuantity: number) {
  if (exactQuantity > 0 && exactQuantity < applicableItems.length) {
    const slicedItems = applicableItems.slice(0, exactQuantity);
    const remainingItems = applicableItems.slice(exactQuantity);
    return { slicedItems: slicedItems, remainingItems: remainingItems };
  } else {
    return { slicedItems: applicableItems, remainingItems: [] };
  }
}

function applyBuysSpecificItemsPromotion(
  promotionAppliedCart: any[],
  promotion: any,
  orderPromotions: any[]
) {
  let modifiedPromotionAppliedCart = promotionAppliedCart;
  const {
    min_qty: minQuantity = 0,
    max_qty: maxQuantity = 0,
    include,
    exclude,
  } = promotion.condition;
  const exactQuantity =
    minQuantity > 0 && minQuantity === maxQuantity ? minQuantity : 0;

  const applyPromotionIfEligible = (
    conditionIncludeCartItems: any[],
    conditionExcludeCartItems: any[]
  ) => {
    if (
      (exactQuantity > 0 &&
        exactQuantity === conditionIncludeCartItems.length) ||
      (minQuantity > 0 &&
        maxQuantity > 0 &&
        minQuantity <= conditionIncludeCartItems.length &&
        maxQuantity >= conditionIncludeCartItems.length)
    ) {
      //only exact quantity of items required for promotion or range between min and max quantity
      return applyPromotionAction(
        promotionAppliedCart,
        promotion,
        orderPromotions
      );
    }
    return promotionAppliedCart;
  };

  if (include.length === 0 && exclude.length === 0) {
    modifiedPromotionAppliedCart = applyPromotionIfEligible(
      promotionAppliedCart,
      []
    );
  } else if (include.length) {
      const conditionIncludeItemInCart = findPromotionApplicableItems(
        promotionAppliedCart,
        include
      );
      const conditionExcludeItemInCart = promotionAppliedCart.filter(
        (item) => !conditionIncludeItemInCart.includes(item)
      );
      modifiedPromotionAppliedCart = applyPromotionIfEligible(
        conditionIncludeItemInCart,
        conditionExcludeItemInCart
      );
  } else {
    const conditionExcludeItemInCart = findPromotionApplicableItems(
      promotionAppliedCart,
      exclude
    );
    const conditionIncludeItemInCart = promotionAppliedCart.filter(
      (item) => !conditionExcludeItemInCart.includes(item)
    );
    modifiedPromotionAppliedCart = applyPromotionIfEligible(
      conditionExcludeItemInCart,
      conditionIncludeItemInCart
    );
  }

  return { modifiedPromotionAppliedCart, orderPromotions };
}

function applyBuysForSpecificAmountPromotion(
  promotionAppliedCart: any[],
  promotion: any,
  orderPromotions: any[]
) {
  const { include, exclude, min_price: minPrice } = promotion.condition;
  let modifiedPromotionAppliedCart = promotionAppliedCart;

  const applyPromotionIfEligible = (
    applicableItems: any[],
  ) => {
    const cartSubtotal = findCartSubtotal(applicableItems);
    if (cartSubtotal > minPrice) {
      return applyPromotionAction(
        promotionAppliedCart,
        promotion,
        orderPromotions
      );
    }
    return promotionAppliedCart;
  };
  if (include.length === 0 && exclude.length === 0) {
    modifiedPromotionAppliedCart = applyPromotionIfEligible(
      promotionAppliedCart
    );
  } else if (include.length) {
      const conditionIncludeItemInCart = findPromotionApplicableItems(
        promotionAppliedCart,
        include
      );
      modifiedPromotionAppliedCart = applyPromotionIfEligible(
        conditionIncludeItemInCart
      );
  } else {
    const conditionExcludeItemInCart = findPromotionApplicableItems(
      promotionAppliedCart,
      exclude
    );
    modifiedPromotionAppliedCart = applyPromotionIfEligible(
      conditionExcludeItemInCart
    );
  }

  return { modifiedPromotionAppliedCart, orderPromotions };
}

function applyAdvancedPromotion(
  promotionAppliedCart: any[],
  promotion: any,
  orderPromotions: any[]
) {
  let promotionCondition = promotion.condition;
  let modifiedPromotionAppliedCart = promotionAppliedCart;
  if (
    promotionCondition.type === PROMOTION_CONDITION_TYPES.BUYS_SPECIFIC_ITEMS
  ) {
    return applyBuysSpecificItemsPromotion(
      promotionAppliedCart,
      promotion,
      orderPromotions
    );
  } else if (
    promotionCondition.type ===
    PROMOTION_CONDITION_TYPES.BUYS_FOR_SPECIFIC_AMOUNT
  ) {
    return applyBuysForSpecificAmountPromotion(
      modifiedPromotionAppliedCart,
      promotion,
      orderPromotions
    );
  } else {
    return {
      modifiedPromotionAppliedCart: promotionAppliedCart,
      orderPromotions,
    };
  }
}

/**
 * Finds cart subtotal
 * @param cartItems - An array of cart items.
 * @param availablePromotions - An array of available promotions.
 * @returns applied promotions, updated cart items and order promotions
 */
function findAppliedPromotions(cartItems: any[], availablePromotions: any[]) {
  let orderPromotion: any[] = [];
  let promotionAppliedCart: any[] = cartItems;
  let appliedPromotions: any[] = []; //find from cart items last
  availablePromotions.forEach((promotion) => {
    if (promotion.promo_type === PROMOTION_TYPES.BASIC) {
      const basicPromotionData = applyBasicPromotion(
        promotionAppliedCart,
        promotion,
        orderPromotion
      );
      promotionAppliedCart = basicPromotionData.promotionAppliedCart;
      orderPromotion = basicPromotionData.orderPromotion;
    } else if (promotion.promo_type === PROMOTION_TYPES.ADVANCED) {
      const advancedPromotionData = applyAdvancedPromotion(
        promotionAppliedCart,
        promotion,
        orderPromotion
      );
      promotionAppliedCart = advancedPromotionData.modifiedPromotionAppliedCart;
      orderPromotion = advancedPromotionData.orderPromotions;
    }
  });
  appliedPromotions = findCartAppliedPromotions(
    promotionAppliedCart,
    availablePromotions
  );
  promotionAppliedCart = remergeCart(promotionAppliedCart); // undo cart changes
  return {
    appliedPromotions,
    promotionAppliedCart,
    orderPromotion,
  };
}

const calculateOrderPromotionDiscount = (promotion:any, subtotal:number, surchargeTotal:any) => {
  let discountAmount = 0;
  let discountableAmount = subtotal + surchargeTotal;
  switch (promotion.action.type) {
    case PROMOTION_ACTIONS.PERCENTAGE_DISCOUNT_ON_ORDER:
      discountAmount = (discountableAmount * promotion.action.value) / 100;
      break;
    case PROMOTION_ACTIONS.VALUE_DISCOUNT_ON_ORDER:
      discountAmount = promotion.action.value;
      break;
    default:
      break;
  }
  return discountAmount;
};

const applyDeliveryPromotion = (promotion:any, surchargeId:number ,surchargeAmount:number) => {
  let promotionDiscount = 0;
  if (
    promotion.action.type === PROMOTION_ACTIONS.GETS_FREE_DELIVERY ||
    promotion.action.type === PROMOTION_ACTIONS.GETS_PERCENTAGE_DISCOUNT_ON_DELIVERY ||
    promotion.action.type === PROMOTION_ACTIONS.GETS_VALUE_DISCOUNT_ON_DELIVERY &&
    promotion.action.surcharge.id === surchargeId
  ) {
    switch (promotion.action.type) {
      case PROMOTION_ACTIONS.GETS_FREE_DELIVERY:
        promotionDiscount = surchargeAmount;
        break;
      case PROMOTION_ACTIONS.GETS_PERCENTAGE_DISCOUNT_ON_DELIVERY:
        promotionDiscount = (surchargeAmount * promotion.action.value) / 100;
        break;
      case PROMOTION_ACTIONS.GETS_VALUE_DISCOUNT_ON_DELIVERY:
        promotionDiscount = promotion.action.value;
        break;
      default:
        break;
    }
  }
  return surchargeAmount - promotionDiscount;
}

/**
 * Checks if an item in the cart has an applied promotion.
 * @param cartItem - The cart item to check.
 * @param promotionAppliedCart - The array of cart items with applied promotions.
 * @returns applied promotion item else 0
 */
const checkItemHasAppliedPromotion = (
  cartItem: any,
  promotionAppliedCart: any[]
) => {
  const appliedPromotion = promotionAppliedCart.find(
    (item) =>
      item.cart_id === cartItem.cart_id &&
      item.applied_promotion_id !== undefined
  );
  return appliedPromotion ? appliedPromotion.applied_promotion_id : 0;
};

export {
  appendCategoryDetails,
  duplicateCartItems,
  findCartSubtotal,
  sortPromotions,
  findAppliedPromotions,
  calculateOrderPromotionDiscount,
  checkItemHasAppliedPromotion,
  applyDeliveryPromotion,
};
