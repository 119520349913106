import GooglePayButton from "@google-pay/button-react/dist";
import React, { useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import { paymentTypeOperations } from "../../../../state/features/paymentTypes";
import { useHistory } from "react-router-dom";
import { loaderOperations } from "../../../../state/features/loader";
import { formatDecimal } from "../../../../helpers/itemCalculations";
import { useCheckoutScreenContext } from "../../../../hooks/useCheckoutScreenContext";
import { getEnvValueFor } from "../../../../helpers/utils";

interface IGooglePayForm {
  orderPayload: any;
  submitGooglePayData(data: any): any;
  showLoader(): void;
  hideLoader(): void;
}

function GooglePayForm({
  orderPayload,
  submitGooglePayData,
  showLoader,
  hideLoader,
}: IGooglePayForm) {
  const { currency, country_code } = useSelector(
    (state: RootState) => state.company
  );
  const { user } = useSelector((state: RootState) => state);
  const { paymentTypes } = useSelector((state: RootState) => state);
  const { name: companyName } = useSelector((state: RootState) => state.company)
  const history = useHistory();
  const googlePayEnv = getEnvValueFor("MPGS_GOOGLE_PAY_ENVIRONMENT");

  const paymentData = useMemo(() => {
    let googlePayMerchantId = "";
    let gateWayMerchantId = "";
    const masterCardData = paymentTypes.find(
      (paymentType: any) => paymentType.payment_type === "mastercard"
    );
    if (masterCardData && Object.keys(masterCardData).length) {
      googlePayMerchantId = masterCardData?.google_pay_merchant_id ?? "";
      gateWayMerchantId = masterCardData?.merchant_id ?? "";
    }
    let totalAmount = orderPayload?.payment?.amount ?? 0;
    let tipsAmount =
      orderPayload?.payment?.tip || 0;
    let payableAmount = totalAmount + tipsAmount;
    const paymentRequest = {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: "CARD",
          parameters: {
            allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
            allowedCardNetworks: ["MASTERCARD", "VISA"],
          },
          tokenizationSpecification: {
            type: "PAYMENT_GATEWAY",
            parameters: {
              gateway: "mpgs",
              gatewayMerchantId: gateWayMerchantId,
            },
          },
        },
      ],
      merchantInfo: {
        merchantId: googlePayMerchantId,
        merchantName: companyName,
      },
      transactionInfo: {
        totalPriceStatus: "FINAL",
        totalPriceLabel: "Total",
        totalPrice: formatDecimal(payableAmount),
        currencyCode: currency,
        countryCode: country_code,
      },
    };
    return paymentRequest;
  }, [orderPayload, currency, country_code, paymentTypes, companyName]);

  const onLoadPaymentData = (paymentData: any) => {
    const currentTime = new Date();
    const paymentReference = `${user.id}${
      user.company_id
    }${currentTime.getTime()}`;
    submitGooglePayData({
      token_data: paymentData,
      order_details: orderPayload,
      reference_id: paymentReference,
    }).then((response: any) => {
      if (response.error) {
        hideLoader();
      } else {
        hideLoader();
        history.push(
          `/payment-processing?reference=${paymentReference}&type=mastercard`
        );
      }
    });
  };

  const onError = (error: any) => {
    console.error(error);
  };

  const clickHandler = () => {
    showLoader();
  };

  return (
    <div className="my-3 w-100">
      <GooglePayButton
        environment={googlePayEnv}
        paymentRequest={paymentData}
        onLoadPaymentData={onLoadPaymentData}
        onError={onError}
        onCancel={() => hideLoader()}
        onClick={clickHandler}
        buttonSizeMode="fill"
        buttonType="pay"
        style={{ height: 40, width: "100%" }}
      />
    </div>
  );
}

const mapDispatchToProps = {
  submitGooglePayData: paymentTypeOperations.submitMastercardGooglePayTokenData,
  showLoader: loaderOperations.showLoader,
  hideLoader: loaderOperations.hideLoader,
};

export default connect(null, mapDispatchToProps)(GooglePayForm);
