import React, { useEffect, useState } from "react";
import MastercardDirectPaymentCardForm from "../cardForm";
import ApplePayButton from "../applePayButton";
import { useCheckoutScreenContext } from "../../../../hooks/useCheckoutScreenContext";

interface IMastercardDirectPaymentWrapper {
  payload: any;
  googlePayEnabled: boolean;
  applePayEnabled: boolean;
  togglePlaceOrderButton(status: boolean): void;
}

function MastercardDirectPaymentWrapper({
  payload,
  googlePayEnabled,
  applePayEnabled,
  togglePlaceOrderButton,
}: IMastercardDirectPaymentWrapper) {
  const [selectedPaymentType, setSelectedPayment] = useState<string>("Card");
  const [addonPaymentTypes, setAddonPaymentTypes] = useState<string[]>([]);
  const { updateMpgsAddonPaymentType } = useCheckoutScreenContext();

  useEffect(() => {
    togglePlaceOrderButton(false);
  }, []);

  useEffect(() => {
    const fetchAddonPaymentTypes = async () => {
      let addonPayments: string[] = [];
      if (googlePayEnabled) {
        addonPayments.push("Google Pay");
      }
      if (applePayEnabled) {
        addonPayments.push("Apple Pay");
      }
      if (addonPayments.length && addonPayments.includes("Apple Pay")) {
        addonPayments = await checkApplePaySupport(addonPayments);
      }
      setAddonPaymentTypes(addonPayments);
    };

    fetchAddonPaymentTypes();
  }, [googlePayEnabled, applePayEnabled]);

  useEffect(() => {
    togglePlaceOrderButton(true);
    updateMpgsAddonPaymentType(selectedPaymentType);
  }, [selectedPaymentType]);

  const checkApplePaySupport = async (addonPaymentTypes: string[]) => {
    if (window?.ApplePaySession) {
      try {
        await window.ApplePaySession.canMakePayments();
        return addonPaymentTypes;
      } catch (error) {
        return addonPaymentTypes.filter((p) => p !== "Apple Pay");
      }
    } else {
      return addonPaymentTypes.filter((p) => p !== "Apple Pay");
    }
  };

  const handleOptionChange = (event: any) => {
    setSelectedPayment(event.target.value);
  };

  const paymentElement = (paymentName: string) => {
    switch (paymentName) {
      case "Apple Pay":
        return <ApplePayButton orderPayload={payload} />;
      default:
        return;
    }
  };

  return (
    <>
      <div className="mastercard-payments-list">
        <label className="payment-type">
          <div>
            <input
              type="radio"
              name="options"
              value="Card"
              defaultChecked
              onChange={handleOptionChange}
            />{" "}
            Card
          </div>
          {selectedPaymentType === "Card" ? (
            <MastercardDirectPaymentCardForm
              orderPayload={payload}
              togglePlaceOrderButton={togglePlaceOrderButton}
            />
          ) : (
            false
          )}
        </label>
        {addonPaymentTypes.map((payment: any) => {
          return (
            <label className="payment-type" key={payment}>
              <div>
                <input
                  type="radio"
                  name="options"
                  value={payment}
                  key={payment}
                  onChange={handleOptionChange}
                />{" "}
                {payment}
              </div>
              {selectedPaymentType === payment ? paymentElement(payment) : null}
            </label>
          );
        })}
      </div>
    </>
  );
}

export default MastercardDirectPaymentWrapper;
