const enum PROMOTION_TYPES {
  BASIC = "basic",
  ADVANCED = "advanced",
}

const enum PROMOTION_ACTIONS {
  VALUE_DISCOUNT = "gets_value_count",
  PERCENTAGE_DISCOUNT = "gets_percent_discount",
  PERCENTAGE_DISCOUNT_ON_ORDER = "gets_percent_discount_on_order",
  VALUE_DISCOUNT_ON_ORDER = "gets_value_discount_on_order",
  PAY_FIXED_PRICE = "pay_fixed_price",
  EARN_LOYALTY = "earn_loyalty",
  PERCENTAGE_DISCOUNT_ON_ITEM = "gets_percent_discount_on_item",
  VALUE_DISCOUNT_ON_ITEM = "gets_value_discount_on_item",
  GETS_FREE_ITEM = "gets_free_item",
  GETS_FREE_DELIVERY = "gets_free_delivery",
  GETS_PERCENTAGE_DISCOUNT_ON_DELIVERY = "gets_percent_delivery_discount",
  GETS_VALUE_DISCOUNT_ON_DELIVERY = "gets_value_delivery_discount",
}

const enum PROMOTION_CONDITION_TYPES {
  BUYS_SPECIFIC_ITEMS = "buys_specific_items",
  BUYS_FOR_SPECIFIC_AMOUNT = "buys_for_specific_amount",
}

type promotionsContextType = {
  appliedPromotionsData: {
    appliedPromotions: any[];
    promotionAppliedCart: any[];
    orderPromotion: any[];
  };
  newlyAppliedPromotions: any[];
  cartVerificationPayload: any;
  showPromotionBanner: boolean;
  promotionCartBreakdowns: any;
  userAcknowledgedPromotionsHandler: (newpromotions: any[]) => void;
};

export {
  PROMOTION_TYPES,
  PROMOTION_ACTIONS,
  promotionsContextType,
  PROMOTION_CONDITION_TYPES,
};
