import React, { useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { paymentTypeOperations } from "../../../../state/features/paymentTypes";
import { RootState } from "../../../../state/store";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

interface IMastercardDirectPaymentCardFormProps {
  orderPayload: any;
  submitMastercardDirectPaymentFormData(payload: any, reference: any): any;
  togglePlaceOrderButton(status: boolean): void;
}

const MastercardDirectPaymentCardForm = ({
  orderPayload,
  submitMastercardDirectPaymentFormData,
  togglePlaceOrderButton,
}: IMastercardDirectPaymentCardFormProps) => {
  const formDataRef = useRef({
    card_holder_name: "",
    card_no: "",
    card_exp_month: "",
    card_exp_year: "",
    cvv: "",
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({
    card_no: "",
    cvv: "",
    card_exp_month: "",
    card_exp_year: "",
  });
  const [cardNumber, setCardNumber] = useState("");
  const currentTime = new Date();
  const { user } = useSelector((state: RootState) => state);
  const history = useHistory();
  const intl = useIntl();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    togglePlaceOrderButton(true);
    let { name, value } = e.target;

    if (
      name === "card_no" ||
      name === "cvv" ||
      name === "card_exp_month" ||
      name === "card_exp_year"
    ) {
      value = value.replace(/\D/g, "");
    }
    switch (name) {
      case "card_no":
        if (value.length === 20) {
          value = value.slice(0, 19);
        }
        const formattedNumber = value
          .replace(/\s/g, "") // Remove existing spaces
          .replace(/(\d{4})/g, "$1 "); // Add a space every four digits
        setCardNumber(formattedNumber.trim()); // Trim any extra spaces
        value = value.replace(/\s/g, "");
        break;
      case "card_exp_month":
      case "card_exp_year":
        if (value.length > 2) {
          value = value.slice(0, 2);
        }
        break;
      case "cvv":
        if (value.length > 3) {
          value = value.slice(0, 3);
        }
        break;
    }

    formDataRef.current = { ...formDataRef.current, [name]: value };

    // Clear error message as user is typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const allErrors = {
    card_no: intl.formatMessage({ id: "message.card_no" }),
    cvv: intl.formatMessage({ id: "message.cvv" }),
    card_exp_month: intl.formatMessage({ id: "message.card_exp_month" }),
    card_exp_year: intl.formatMessage({ id: "message.card_exp_year" }),
  };

  const validateField = (name: string, value: string) => {
    let error = "";
    switch (name) {
      case "card_no":
        if (!value || value.length < 12 || value.length > 20) {
          error = allErrors.card_no;
        }
        break;
      case "cvv":
        if (!value || value.length !== 3) {
          error = allErrors.cvv;
        }
        break;
      case "card_exp_month":
        if (!value || parseInt(value) > 12) {
          error = allErrors.card_exp_month;
        }
        break;
      case "card_exp_year":
        if (!value || value.length !== 2) {
          error = allErrors.card_exp_year;
        }
        break;
    }
    return error;
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");
    const error = validateField(name, sanitizedValue);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const validateForm = () => {
    let newErrors: { [key: string]: string } = {};
    let hasErrors = false;

    Object.keys(formDataRef.current).forEach((key) => {
      const error = validateField(key, formDataRef.current[key]);
      if (error) {
        newErrors[key] = error;
        hasErrors = true;
      }
    });

    setErrors(newErrors);
    return hasErrors;
  };

  const handleSubmit = (e: any) => {
    togglePlaceOrderButton(false);
    e.preventDefault();
    const hasErrors = validateForm();
    if (!hasErrors) {
      let newPayload = {
        ...orderPayload,
        card_data: {
          number: formDataRef.current.card_no,
          scheme: "",
          exp_month: formDataRef.current.card_exp_month,
          exp_year: formDataRef.current.card_exp_year,
          type: "mastercard",
          payment_method: "card",
          security_code: formDataRef.current.cvv,
        },
      };
      const paymentReference = `${user.id}${
        user.company_id
      }${currentTime.getTime()}`;
      submitMastercardDirectPaymentFormData(newPayload, paymentReference).then(
        (response: any) => {
          if (!response.error) {
            history.push(
              `/payment-processing?reference=${paymentReference}&type=mastercard`
            );
          }
        }
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mastercard-mpgs-form-mobile">
      <div>
        <input
          type="text"
          id="card_holder_name"
          name="card_holder_name"
          value={formDataRef.current.card_holder_name}
          onChange={handleChange}
          className="form-input-long"
          placeholder="Card Holder Name"
          required
          autoFocus
        />
      </div>
      <div>
        <input
          type="text"
          id="card_no"
          name="card_no"
          value={cardNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          className="form-input-long"
          placeholder="Card Number"
          required
        />
        <div className="error-message">{errors.card_no}</div>
      </div>
      <div className="form-multiple-input-line">
        <div>
          <input
            type="number"
            id="card_exp_month"
            name="card_exp_month"
            value={formDataRef.current.card_exp_month}
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={2}
            placeholder="MM"
            className="form-input-short"
            required
          />
          <div className="error-message">{errors.card_exp_month}</div>
        </div>
        <div>
          <input
            type="number"
            id="card_exp_year"
            name="card_exp_year"
            value={formDataRef.current.card_exp_year}
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={2}
            placeholder="YY"
            className="form-input-short"
            required
          />
          <div className="error-message">{errors.card_exp_year}</div>
        </div>
        <div>
          <input
            type="number"
            id="cvv"
            name="cvv"
            value={formDataRef.current.cvv}
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={3}
            placeholder="CVV"
            className="form-input-short"
            required
          />
          <div className="error-message">{errors.cvv}</div>
        </div>
      </div>
      <button
        type="submit"
        id="mastercardDirectPaymentCheckoutButton"
        className="hidden"
      ></button>
    </form>
  );
};

const mapDispatchToProps = {
  submitMastercardDirectPaymentFormData: paymentTypeOperations.submitMastercardDirectPaymentFormData,
};

export default connect(null, mapDispatchToProps)(MastercardDirectPaymentCardForm);
