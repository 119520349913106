import * as types from "./types";

const verifyPayment = (tmpOrderID: any) => ({
  type: types.VERIFY_PAYMENT,
  payload: {
    request: {
      method: "post",
      url: "payments/verifications",
      data: {
        tmp_order_id: tmpOrderID,
      }
    }
  }
});

const buildCCAvenuePaymentData = (order_details: any) => ({
  type: types.BUILD_CC_AVENUE_PAYMENT_DATA,
  payload: {
    request: {
      method: "post",
      url: "payments/cc_avenue/v1/payment_pages",
      data: { order_details: order_details },
    }
  }
});

const fetchMyFatoorahPaymentModes = (totalAmount: any) => ({
  type: types.FETCH_MY_FATOORAH_PAYMENT_MODES,
  payload: {
    request: {
      method: "get",
      url: `payments/my_fatoorah/v1/payment_modes?total_amount=${totalAmount}`,
    }
  }
});

const buildQpayPaymentData = (order_details: any) => ({
  type: types.BUILD_QPAY_PAYMENT_DATA,
  payload: {
    request: {
      method: "post",
      url: "payments/qpay/v1/payment_pages",
      data: { order_details: order_details },
    }
  }
});

const buildIPay88PaymentData = (order_details: any) => ({
  type: types.BUILD_I_PAY88_PAYMENT_DATA,
  payload: {
    request: {
      method: "post",
      url: "payments/i_pay88/v1/payment_pages",
      data: { order_details: order_details },
    }
  }
});

const buildFiservPaymentData = (order_details: any) => ({
  type: types.BUILD_FISERV_PAYMENT_DATA,
  payload: {
    request: {
      method: "post",
      url: "payments/fiserv/v1/payment_pages",
      data: { order_details: order_details },
    }
  }
});

const omisePlaceOrder = (order_details: any) => ({
  type: types.OMISE_PLACE_ORDER,
  payload: {
    request: {
      method: "post",
      url: "payments/omise/v1/orders",
      data: { order_details: order_details },
    }
  }
});

const buildAdyenPaymentMethods = (amount: any) => ({
  type: types.BUILD_ADYEN_PAYMENT_METHODS,
  payload: {
    request: {
      method: "get",
      url: `payments/adyen/v1/payment_modes?amount=${amount}`
    }
  }
});


const createAdyenPayment = (payment_method: any, order_details: any) => ({
  type: types.CREATE_ADYEN_PAYMENT,
  payload: {
    request: {
      method: "post",
      url: "payments/adyen/v1/transactions",
      data: { payment_method: payment_method, order_details: order_details }
    }
  }
});

const createGeideaTempOrder = (order_details: any) => ({
  type: types.CREATE_GEIDEA_TEMP_ORDER,
  payload: {
    request: {
      method: "post",
      url: "payments/geidea/v1/temp_orders",
      data: { order_details: order_details },
    }
  }
});

const buildMastercardSession = (order_details: any) => ({
  type: types.BUILD_MASTERCARD_SESSION,
  payload: {
    request: {
      method: "post",
      url: "sapaad_payments/mastercard/v1/sessions",
      data: { order_details: order_details },
    }
  }
});


const submitMastercardDirectPaymentFormData = (orderDetails: any, paymentReference:any) => ({
  type: types.SUBMIT_MASTERCARD_DIRECT_PAYMENT_FORM_DATA,
  payload: {
    request: {
      method: "post",
      url: "/sapaad_payments/mastercard/direct_payments/v1/card_payments",
      data: { order_details: orderDetails, reference_id: paymentReference },
    }, 
  },
});

const validateMastercardApplePayMerchant = (
  validationURL: string,
  orderPayload: any,
  paymentReference: string
) => ({
  type: types.VALIDATE_MASTERCARD_APPLE_PAY_MERCHANT,
  payload: {
    request: {
      method: "post",
      url: "/sapaad_payments/mastercard/direct_payments/v1/apple_pay_payments/start_apple_pay_session",
      data: {
        validation_url: validationURL,
        order_details: orderPayload,
        reference_id: paymentReference,
      },
    },
  },
});

const makeMastercardApplePayPayment = (paymentDetails: any, paymentReferenceId: string) => ({
  type: types.MAKE_MASTERCARD_APPLE_PAY_PAYMENT,
  payload: {
    request: {
      method: "post",
      url: "/sapaad_payments/mastercard/direct_payments/v1/apple_pay_payments/process_payment",
      data: { payment_details: paymentDetails, reference_id: paymentReferenceId },
    }
  }
});

const submitMastercardGooglePayTokenData = (tokenData: any) => ({
  type: types.SUBMIT_MASTERCARD_GOOGLE_PAY_TOKEN,
  payload: {
    request: {
      method: "post",
      url: "/sapaad_payments/mastercard/direct_payments/v1/google_pay_payments/process_payment",
      data: tokenData,
    },
  },
});

const userCancelledApplePayPayment = (referenceId: string) => ({
  type: types.USER_CANCELLED_APPLE_PAY_PAYMENT,
  payload: {
    request: {
      method: "put",
      url: "/sapaad_payments/mastercard/direct_payments/v1/apple_pay_payments/cancel_payment",
      data: { reference_id: referenceId },
    }
  }
});

const initiateStripeConnectTransaction = (order_details: any, paymentReference: string) => ({
  type: types.INITIATE_STRIPE_CONNECT_TRANSACTION,
  payload: {
    request: {
      method: "post",
      url: "sapaad_payments/stripe_connect/v1/payment_intents",
      data: { order_details: order_details, reference_id: paymentReference },
    }
  }
});

const fetchStripeConnectPaymentIntent = (reference: any) => ({
  type: types.FETCH_STRIPE_CONNECT_PAYMENT_INTENT,
  payload: {
    request: {
      method: "get",
      url: `sapaad_payments/stripe_connect/v1/payment_intents/${reference}`
    }
  }
});

const buildGenericPaymentsPayload = (order_details: any, user: any, payment_type: any) => ({
  type: types.GENERIC_PAYMENT_TYPE,
  payload: {
    request: {
      method: "post",
      url: "sapaad_payments/generic_payments/payments",
      data: { order_details: order_details, user: user, payment_type: payment_type }
    }
  }
})

export default {
  verifyPayment,
  buildCCAvenuePaymentData,
  fetchMyFatoorahPaymentModes,
  buildQpayPaymentData,
  buildIPay88PaymentData,
  buildFiservPaymentData,
  buildMastercardSession,
  submitMastercardDirectPaymentFormData,
  validateMastercardApplePayMerchant,
  makeMastercardApplePayPayment,
  userCancelledApplePayPayment,
  submitMastercardGooglePayTokenData,
  omisePlaceOrder,
  buildAdyenPaymentMethods,
  createAdyenPayment,
  createGeideaTempOrder,
  initiateStripeConnectTransaction,
  fetchStripeConnectPaymentIntent,
  buildGenericPaymentsPayload
};
