// Packages
import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Pusher from "pusher-js";
import { FormattedMessage } from 'react-intl';
// Components
import Header from "../../components/header";
import InlineLoader from "../../components/inlineLoader";
import OrderPlacedPopup from "../../components/orderPlacedPopup";
// Redux Operations
import { pageOperations } from "../../state/features/page";
import { paymentTypeOperations } from "../../state/features/paymentTypes";
import { cartOperations } from "../../state/features/cart";
// Contexts
import { EnvContext } from "../../components/envContext";
import { MASTERCARD_PAYMENT_TYPE } from "../../helpers/constants";

interface IPaymentProcessingProps {
  location: any;
  fetchPaymentProcessingConfigurations: any;
  verifyPayment: any;
  setCart: any;
}

function PaymentProcessing(props: IPaymentProcessingProps) {
  const [paymentStatus, setPaymentStatus] = useState("in_progress");
  const [earnedLoyalty, setEarnedLoyalty] = useState(null as any);
  const [redirect, setRedirect] = useState(false);
  const [redirectConfig, setRedirectConfig] = useState({});
  const [searchQueryLoaded, setSearchQueryLoaded] = useState(false);
  const [configLoaded, setConfigLoaded] = useState(false);
  const [tmpOrderID, setTmpOrderID] = useState(null);
  const [paymentReference, setPaymentReference] = useState("");
  const [paymentType, setPaymentType] = useState("");

  const { pusherId } = useContext(EnvContext);

  useEffect(() => {
    if (props.location) {
      let urlParams = new URLSearchParams(props.location.search);
      let reference = urlParams.get("reference");
      let type = urlParams.get("type");

      setPaymentReference(reference);
      setPaymentType(type);
      setSearchQueryLoaded(true);
    }
  }, [props.location]);

  useEffect(() => {
    if (searchQueryLoaded) {
      // Fetch Configurations for processing payment
      props
        .fetchPaymentProcessingConfigurations(paymentReference, paymentType)
        .then((response: any) => {
        if (!response.error) {
          const paymentDetails = response.payload.data?.payment_details;

          setPaymentReference(paymentDetails?.reference);
          setPaymentType(paymentDetails?.type);
          setPaymentStatus(paymentDetails?.status);
          setEarnedLoyalty(paymentDetails?.earned_loyalty);
          setTmpOrderID(paymentDetails?.tmp_order_id);

          setConfigLoaded(true);
        }
      });
    } 
  }, [searchQueryLoaded]);

  useEffect(() => {
    if (configLoaded && paymentStatus) {
      processPaymentStatus();
    }
  }, [configLoaded, paymentStatus]);

  const processPaymentStatus = () => {
    switch (paymentStatus) {
      case "in_progress":
        bindToPusherChannel();
        break;
      case "paid":
        resetCart();
        break;
      case "declined":
        performRedirection("declined");
        break;
    }
  }

  const bindToPusherChannel = () => {
    // Connect to Pusher
    const pusher = new Pusher(pusherId, {});
    let channel
    // Subscribe to respective payment channel
    switch (paymentType) {
      case "paytabs":
        channel = pusher.subscribe(`paytabs_payment_${paymentReference}`);
        break;
      case MASTERCARD_PAYMENT_TYPE:
        channel = pusher.subscribe(`mastercard_payment_${paymentReference}`);
        break;  
      case "pay_u":
        channel = pusher.subscribe(`pay_u_payment_${paymentReference}`);
        break;
      case "i_pay88":
        channel = pusher.subscribe(`i_pay88_payment_${paymentReference}`);
        break;
      case "adyen":
        channel = pusher.subscribe(`adyen_payment_${paymentReference}`);
        break;
      case "geidea":
        channel = pusher.subscribe(`geidea_payment_${paymentReference}`);
        break;
      case "stripe_connect":
        channel = pusher.subscribe(`private_stripe_connect_transaction_${paymentReference}`);
        break;
      case "generic_fintech_partner":
        channel = pusher.subscribe(`generic_payment_transaction_${paymentReference}`);
        break;
    }

    if (channel) {
      const verifyPayment = () => {
        // Check status of payment once timeout exceeded
        props.verifyPayment(tmpOrderID).then((response: any) => {
          if (!response.error) {
            const paymentDetails = response.payload.data;

            if (paymentDetails?.status != "in_progress") {
              channel.unbind();

              clearInterval(periodicStatusChecker);
              clearTimeout(timeOutHandler);

              setPaymentStatus(paymentDetails?.status);
              setEarnedLoyalty(paymentDetails?.earned_loyalty);
            }
          }
        });
      }

      // Initial Check in case Pusher message received before Loading pusher
      var initialStatusChecker = setTimeout(function(){
        clearTimeout(initialStatusChecker);
        
        verifyPayment();
      }, 5000);

      // Polls every few seconds if pusher message is not received
      var periodicStatusChecker = setInterval(function(){
        clearTimeout(initialStatusChecker);
        
        verifyPayment();
      }, 30000);

      // Handle Timeout
      var timeOutHandler = setTimeout(function(){
        clearInterval(periodicStatusChecker);
        clearTimeout(initialStatusChecker);
        clearTimeout(timeOutHandler);
        channel.unbind();

        setPaymentStatus("declined");
      }, 305000);

      // Handle Successful Payment
      channel.bind("payment_succeeded", (data: any) => {
        clearInterval(periodicStatusChecker);
        clearTimeout(initialStatusChecker);
        clearTimeout(timeOutHandler);

        setPaymentStatus("paid");
        setEarnedLoyalty(data.earned_loyalty);
      });

      // Handle Failed Payment
      channel.bind("payment_failed", (data: any) => {
        clearInterval(periodicStatusChecker);
        clearTimeout(initialStatusChecker);
        clearTimeout(timeOutHandler);

        setPaymentStatus("declined");
      });
    }
  }

  const resetCart = () => {
    localStorage.cartItems = JSON.stringify([]);
    props.setCart({ items: [], subTotal: 0, total: 0 });
  }

  const performRedirection = (reason) => {
    switch (reason) {
      case "declined":
        setRedirectConfig({
          pathname: "/checkout",
          search: "?payment_status=failed",
        });
      break;
    }
    setTimeout(() => {
      setRedirect(true);
    }, 5000);
  };

  return (
    <React.Fragment>
      {redirect ? <Redirect to={redirectConfig} /> : null}
      <Header />
      <div className="payment-processing">
        <InlineLoader />
        {paymentStatus == "in_progress" ? (
          <React.Fragment>
            <h1 className="header">
              <FormattedMessage
                id="payment.in_progress"
                defaultMessage="Payment in Progress"
              />
            </h1>
            <p className="message">
              <FormattedMessage
                id="payment.processing_do_not_close_or_reload"
                defaultMessage="We are processing your payment. Please do not close or reload this page."
              />
            </p>
          </React.Fragment>
        ) : paymentStatus == "paid" ? (
          <OrderPlacedPopup earnedLoyalty={earnedLoyalty} />
        ) : (
          <React.Fragment>
            <h1 className="header">
              <FormattedMessage
                id="payment.declined"
                defaultMessage="Payment Declined"
              />
            </h1>
            <p className="message">
              <FormattedMessage
                id="payment.sorry"
                defaultMessage="Sorry."
              />
            </p>
            <p className="message">
              <FormattedMessage
                id="payment.declined_redirect_to_checkout"
                defaultMessage="Your payment has been declined. You will be redirected to the checkout. Please do not close or reload this tab."
              />
            </p>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}

const mapDispatchToProps = {
  fetchPaymentProcessingConfigurations: pageOperations.fetchPaymentProcessingConfigurations,
  verifyPayment: paymentTypeOperations.verifyPayment,
  setCart: cartOperations.setCart,
};

export default connect(null, mapDispatchToProps)(PaymentProcessing);
