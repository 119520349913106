// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import * as React from "react";
import * as ReactDOM from "react-dom";
import $ from "jquery";
import "./app.scss";
import { BrowserRouter } from "react-router-dom";
import App from "../components/app";
import { Provider } from "react-redux";
import { store } from "../state/store";
import MobileApp from "../mobileComponents/app";
import { EnvProvider } from "../components/envContext";

// Translations
import LocaleWrapper from "../wrappers/localeWrapper";
import PromotionsProvider from "../features/promotions/providers/promotionsProvider";

document.addEventListener("DOMContentLoaded", () => {
  let root = document.getElementById("root") as HTMLDivElement;
  ReactDOM.render(
    <EnvProvider envObject={root.dataset}>
      <Provider store={store}>
        <BrowserRouter>
          <LocaleWrapper>
            <PromotionsProvider>
              {screen.width >= 768 ? <App /> : <MobileApp />}
            </PromotionsProvider>
          </LocaleWrapper>
        </BrowserRouter>
      </Provider>
    </EnvProvider>,
    root
  );
});
