import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { useCheckoutScreenContext } from "../../hooks/useCheckoutScreenContext";
import GooglePayForm from "../../components/paymentTypes/mastercardDirectPayment/googlePayForm";

interface ICheckoutCtaButtonProps {
  placeOrderEnabled: boolean;
  outOfStockItems: any[];
  recentOrder: any;
  orderPayload: any;
  storeOpen: boolean;
  cartItems: any[];
  activeStep: any;
  clickAction: () => void;
  backAction: () => void;
}

const CheckoutCtaButton = ({
  placeOrderEnabled,
  outOfStockItems,
  orderPayload,
  recentOrder,
  storeOpen,
  cartItems,
  activeStep,
  clickAction,
  backAction,
}: ICheckoutCtaButtonProps) => {
  const { paymentTypes } = useSelector((state: RootState) => state);
  const { mpgsAddonPaymentType, checkoutPageConfigs } =
    useCheckoutScreenContext();

  const disableCtaButton: boolean = useMemo(() => {
    return (
      !storeOpen ||
      !placeOrderEnabled ||
      !cartItems?.length ||
      (outOfStockItems.length && activeStep === 2) ||
      (recentOrder && activeStep === 2) ||
      !checkoutPageConfigs.showPaymentMethods
    );
  }, [
    placeOrderEnabled,
    outOfStockItems,
    recentOrder,
    checkoutPageConfigs.showPaymentMethods,
  ]);

  const isMastercardDirectPayment = useMemo(() => {
    const masterCardPaymentType = paymentTypes.find(
      (p) => p.payment_type === "mastercard"
    );
    if (orderPayload?.payment?.payment_type === "mastercard") {
      return masterCardPaymentType?.direct_payment_enabled && true;
    }
    return false;
  }, [orderPayload]);

  if (
    activeStep === 2 &&
    isMastercardDirectPayment &&
    !disableCtaButton &&
    mpgsAddonPaymentType !== "Card"
  ) {
    if (mpgsAddonPaymentType === "Google Pay") {
      return <GooglePayForm orderPayload={orderPayload} />;
    } else if (mpgsAddonPaymentType === "Apple Pay") {
      return (
        <button
          className="apple-pay-button apple-pay-button-black w-100"
          style={{ height: "45px" }}
          onClick={clickAction}
        ></button>
      );
    } else {
      return null;
    }
  } else {
    return (
      <React.Fragment>
        <button
          className="button btn btn-outline-primary mx-1"
          onClick={backAction}
        >
          <FormattedMessage
            id="global.back"
            defaultMessage="Back"
          />
        </button>
        <button
          className="button btn btn-primary mx-1"
          onClick={clickAction}
          disabled={disableCtaButton}
        >
          {activeStep === 2 ? (
            <FormattedMessage
              id="checkout.place_order"
              defaultMessage="Place order"
            />
          ) : (
            <FormattedMessage
              id="global.next"
              defaultMessage="Next"
            />
          )}
          <span className="ml-3">
            <FontAwesomeIcon icon={faChevronCircleRight} />
          </span>
        </button>
      </React.Fragment>
    );
  }
};

export default CheckoutCtaButton;
