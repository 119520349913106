// Packages
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from 'react-intl';
// Components
import ModalCloseIcon from "../../components/modalCloseIcon";
import OTPInput from "../../mobileComponents/otpInput";
import DialingCodeSelector from "../../mobileComponents/dialingCodeSelector";
import OtpTimer from "../../components/otpTimer";
// Icons, Images etc.
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Redux Operations
import { userOperations } from "../../state/features/user";
import { loaderOperations } from "../../state/features/loader";
import { cartOperations } from "../../state/features/cart";
import { errorOperations } from "../../state/features/error";
import { usePromotionsContext } from "../../hooks/usePromotionsContext";

interface IMobileVerificationProps {
  onClose: any;
  postRegistrationPage: string;
  showLoader: any;
  setShowEmailOtp: (value: boolean) => void;
  showEmailOtp: boolean;
  userPayload: any;
  company: any;
  smsDisabled: boolean;
  signIn: (data: any, token: string) => void;
  sendOtp: (token: string) => void;
  resendOtp: (token: string) => void;
  sendOtpViaEmail: (payload: any, token: string) => void;
  resendOtpViaEmail: (payload: any, token: string) => void;
  validateCart(cart: any[]): Promise<any>;
  hideLoader(): void;
  updateError(error: any): void;
}

function MobileVerification(props: IMobileVerificationProps) {
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("" as boolean | string);
  const [redirectURL, setRedirectURL] = useState("" as null | string);
  const [showOtp, setShowOtp] = useState(true);
  const [otpTimeout, setOtpTimeout] = useState(false);
  const [newOtpRequested, setNewOtpRequested] = useState(false);
  const userRef = useRef<null|any>(null);
  const { cartVerificationPayload } = usePromotionsContext();
  const intl = useIntl();

  const csrfToken = document.querySelector('[name=csrf-token]').content;

  useEffect(() => {
    localStorage.setItem("otpStatus", "mobile");
    sendOtp();
  }, []);

  const sendOtp = () => {
    const handlePostOtpRequest = (response: any) => {
      if (response.error) {
        handleClose();
      }
    };
    if (!props.showEmailOtp) {
      props.sendOtp(csrfToken).then(handlePostOtpRequest);
    } else {
      props
        .sendOtpViaEmail({ email: props.userPayload.email }, csrfToken)
        .then(handlePostOtpRequest);
    }
  };

  const resendOtp = () => {
    setOtpError("");
    setShowOtp(false);
    const handlePostOtpRequest = (response: any) => {
      if (response.error) {
        handleClose();
      } else {
        setShowOtp(true);
        setOtpTimeout(false);
      }
    };
    if (!props.showEmailOtp) {
      props.resendOtp(csrfToken).then((response: any) => {
        if (!response.error) {
          setNewOtpRequested(true);
        }
        handlePostOtpRequest(response);
      });
    } else {
      props
        .resendOtpViaEmail({ email: props.userPayload.email }, csrfToken)
        .then((response: any) => {
          if (!response.error) {
            setNewOtpRequested(true);
          }
          handlePostOtpRequest(response);
        });
    }
  };

  const handleClose = () => {
    props.onClose(), 1000;
  };

  const submitOtp = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    props.signIn({ otp: otp }, csrfToken).then((response: any) => {
      if (!response.error) {
        userRef.current = response.payload.data;
        onUserActionComplete();
      } else if (response.error.response.data.message) {
        setOtpError(
          response.error.response.data.message || "Oops! Something went wrong."
        );
      }
    });
  };

  const redirectToPostRegistrationPage = () => {
    if (props.postRegistrationPage) {
      localStorage.removeItem("postRegistrationPage");
      localStorage.removeItem("otpStatus");
      setRedirectURL(props.postRegistrationPage);
    } else {
      setRedirectURL("/m");
    }
  };

  const validateCartAndRedirect = async () => {
    props.showLoader();
    const customerId = userRef.current.id;
    const customerName = userRef.current.name ?? "";
    const customerEmail = userRef.current.email ?? "";
    const updatedPayload = {
      ...cartVerificationPayload,
      customer_id: customerId,
      user_name: customerName,
      customer_email: customerEmail,
    }; //updating with user details
    try {
      const result = await props.validateCart(updatedPayload);
      if (!result.error && result.payload.data.validated) {
        redirectToPostRegistrationPage();
      } else {
        props.hideLoader();
        handleCartValidationFailure();
      }
    } catch (error) {
      handleCartValidationFailure();
    } finally {
      props.hideLoader();
    }
  };

  const handleCartValidationFailure = () => {
    handleClose();
    props.hideLoader();
    props.updateError({
      title: intl.formatMessage({ id: "error.oops", defaultMessage: "Oops!" }),
      message: intl.formatMessage({
        id: "error.something_went_wrong",
        defaultMessage: "Something went wrong. Please try again later.",
      }),
    });
  };

  const onUserActionComplete = () => {
    props.showLoader();
    validateCartAndRedirect();
  };

  const handleOTPInput = (otp: any) => {
    setOtpError("");
    setOtp(otp);
  };

  return (
    <React.Fragment>
      {redirectURL ? (
        <Redirect to={redirectURL} />
      ) : (
        <div className="login-screen otp-verify-screen">
          <div className="user-login px-3 py-3 mb-4 mt-4">
            <h1 className="header">
              <FormattedMessage
                id="registration.otp.enter_the_otp_code"
                defaultMessage="Enter the OTP Code"
              />
            </h1>
            <div>
              <h3 className="mt-2 mb-3 text-center">
                {!props.showEmailOtp ? (
                  <FormattedMessage
                    id="registration.otp.enter_code_sent_to_phone"
                    defaultMessage="Please enter the code we have sent to <pTag>{mobileNumber}</pTag>."
                    values={{
                      mobileNumber: `+${props.userPayload.dialing_code} ${props.userPayload.phone_number}`,
                      pTag: (mobileNumber: any) => (
                        <p className="font-weight-bold">{mobileNumber}</p>
                      ),
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="registration.otp.enter_code_sent_to_email"
                    defaultMessage="Please enter code we sent to <pTag>{email}</pTag>."
                    values={{
                      email: `${props.userPayload.email}`,
                      pTag: (email: any) => (
                        <p className="font-weight-bold">{email}</p>
                      ),
                    }}
                  />
                )}
              </h3>
              {newOtpRequested && (
                <p className="otp-resent-info">
                  <FormattedMessage
                    id="registrations.otp.resend_requested"
                    defaultMessage="A new OTP has been sent"
                  />
                </p>
              )}
              <form className="text-center" onSubmit={submitOtp}>
                {showOtp ? (
                  <OTPInput
                    dir="ltr"
                    autoFocus
                    length={4}
                    className="otpContainer"
                    inputClassName="otpInput form-control"
                    onChangeOTP={(otp: any) => handleOTPInput(otp)}
                  />
                ) : null}
                {otpError ? (
                  <div className="input-label text-center text-danger">
                    <FormattedMessage
                      id="registration.otp.incorrect_otp"
                      defaultMessage="Incorrect OTP."
                    />
                  </div>
                ) : null}
                <button className="btn button btn-primary w-100 py-2 mt-4 mb-3">
                  <FormattedMessage
                    id="global.continue"
                    defaultMessage="Continue"
                  />
                  <span className="ml-3">
                    <FontAwesomeIcon icon={faChevronCircleRight} />
                  </span>
                </button>
                <p className="other-actions otp-resend">
                  <FormattedMessage
                    id="registration.otp.receive_question"
                    defaultMessage="Didn't receive the OTP?"
                  />
                </p>
                {!otpTimeout ? (
                  <p className="other-actions otp-wait">
                    <OtpTimer setOtpTimeout={setOtpTimeout} />
                  </p>
                ) : (
                  <p
                    className="other-actions otp-resend-action py-2"
                    onClick={resendOtp}
                  >
                    <FormattedMessage
                      id="registrations.otp.resend_button"
                      defaultMessage="Resend"
                    />
                  </p>
                )}
              </form>
            </div>
          </div>
          {"email" in props.company.otp_settings &&
            props.company.otp_settings.email &&
            !props.showEmailOtp ? (
              <p
                className="other-actions change-otp-mode"
                onClick={() => {
                  handleClose();
                  props.setShowEmailOtp(true);
                }}
              >
                <span>
                  <FormattedMessage
                    id="registration.otp.sent_otp_to_my_email"
                    defaultMessage="Send OTP to my email"
                  />
                </span>
              </p>
            ) : !props.smsDisabled && props.showEmailOtp ? (
              <p
                className="other-actions change-otp-mode"
                onClick={() => {
                  handleClose();
                  props.setShowEmailOtp(false);
                }}
              >
                <span>
                  <FormattedMessage
                    id="registrations.otp.receive_otp_via_sms"
                    defaultMessage="Receive OTP via SMS"
                  />
                </span>
              </p>
            ) : null}
        </div>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state: any) => {
  let company = state.company;
  let smsDisabled = company?.sms_disabled || false;

  return { 
    company,
    smsDisabled
  };
};

const mapDispatchToProps = {
  sendOtp: userOperations.sendOtp,
  resendOtp: userOperations.resendOtp,
  signIn: userOperations.signIn,
  showLoader: loaderOperations.showLoader,
  sendOtpViaEmail: userOperations.sendOtpViaEmail,
  resendOtpViaEmail: userOperations.resendOtpViaEmail,
  validateCart: cartOperations.validateCart,
  hideLoader: loaderOperations.hideLoader,
  updateError: errorOperations.updateError,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileVerification);
